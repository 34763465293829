import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';

import Table from './Table';
import ModalCreateRecord from './Create';
import { ModalState, ModalTypes } from '../../../types';

const Records: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState<ModalState | null>(null);
  const closeModal = () => {
    setModal(null);
  };

  const openCreateModal = () => {
    setModal({ type: ModalTypes.create });
  };

  return (
    <Content>
      <ModalCreateRecord isOpen={modal?.type === ModalTypes.create} close={closeModal} />
      <Table openModal={openCreateModal} />
    </Content>
  );
};

export default Records;
