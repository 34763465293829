import moment from 'moment';
import Search from 'antd/es/input/Search';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons/lib';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, Button, FormInstance, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Table from '../../../Common/Table';
import SelectTemplate from '../../../Common/SelectTemplate';
import SelectWarehouse from '../../../Common/SelectWarehouse';
import ModalSendReport from '../../../Common/ModalSendReport';
import { getMessageInError } from '../../../../hooks/fetch';
import { ReportStatus, ReportStatuses } from '../../../../enums/reports';
import { QcTableReportRow, useTableQcReportRow } from '../../../../hooks/qcReports';
import { capitalizeFirstLetter, getSorterParams, queryFilterParams } from '../../../../utils';
import SelectUsers from '../../../Common/SelectUsers';

const TableQcReports: React.FC = (): JSX.Element => {
  const formRef = useRef<FormInstance>();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const qcReportsGet = useTableQcReportRow();
  const [searchParams, setSearchParams] = useSearchParams();

  const [modal, setModal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      orderBy: searchParams.get('orderBy') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      search: value,
      bolAwb: searchParams.get('bolAwb') || '',
      id: searchParams.get('id') || '',
      key: searchParams.get('key') || '',
      warehouse: searchParams.get('warehouse') || '',
      template: searchParams.get('template') || '',
      updated: searchParams.get('updated') || '',
      created: searchParams.get('created') || '',
      generatedAt: searchParams.get('generatedAt') || '',
      userId: searchParams.get('userId') || '',
      completedById: searchParams.get('completedById') || '',
      recordType: searchParams.get('recordType') || '',
      status: searchParams.get('status') || '',
      attachments: searchParams.get('attachments') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if (searchParams.get('recordType') !== 'misc') {
      formRef.current?.setFieldsValue({ recordType: 'bol' });
    }
  }, []);

  useEffect(() => {
    if (qcReportsGet.error) {
      message.error(getMessageInError(qcReportsGet.error));
      qcReportsGet.clearError();
    }
  }, [qcReportsGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    <Button
      key="button"
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => navigate('/reports/qc/create', { replace: true })}
    >
      Add New
    </Button>,
  ], [searchValue, onSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<QcTableReportRow>>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...{ ...args, recordType: args.recordType || 'bol' },
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return qcReportsGet.fetch(params).then((data) => {
      if (data) {
        const { qcReports, total } = data;

        return ({ data: qcReports || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  }, [searchParams.get('search')]);

  const getQcReportsBadge = (status: ReportStatus): JSX.Element => {
    switch (status) {
      case ReportStatuses['in progress'].toLowerCase():
        return <Badge status="warning" text={ReportStatuses['in progress']} />;
      case ReportStatuses.completed.toLowerCase():
        return <Badge status="success" text={ReportStatuses.completed} />;
      case ReportStatuses.expired.toLowerCase():
        return <Badge status="default" text={ReportStatuses.expired} />;
      case ReportStatuses['not started'].toLowerCase():
        return <Badge status="error" text={ReportStatuses['not started']} />;
      default:
        return <Badge status="default" text={capitalizeFirstLetter(ReportStatuses[status])} />;
    }
  };

  useEffect(() => {
    if (!searchParams.get('recordType')) {
      formRef.current?.setFieldsValue({ recordType: 'bol' });
    }
  }, [searchParams]);

  const columns: ProColumns<QcTableReportRow>[] = [
    {
      title: searchParams.get('recordType') === 'misc' ? 'Title' : 'BOL# ',
      dataIndex: 'bolAwb',
      sorter: true,
      renderText: (bolAwb, { id }) => <Link to={`/reports/qc/${id}`}>{bolAwb}</Link>,
    },
    {
      title: 'Type',
      dataIndex: 'recordType',
      sorter: false,

      order: 1,
      valueEnum: {
        bol: 'BOL',
        misc: 'MISC',
      },
      hideInTable: true,
      formItemProps: {
        label: 'Record type',
      },
      renderText: (_, { record }) => record?.type,
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      sorter: true,
      renderText: (warehouse) => warehouse?.name || '',
      renderFormItem: (_, { ...config }) => <SelectWarehouse {...config} />,
    },
    {
      title: 'Template',
      dataIndex: 'templates',
      sorter: true,
      search: { transform: () => 'template' },
      renderFormItem: (_, { ...config }) => <SelectTemplate {...config} />,
    },
    {
      title: 'Updated at',
      dataIndex: 'updated',
      valueType: 'dateRange',
      sorter: true,
      render: (_, { updated }) => updated ? moment(updated).format('MM/DD/YYYY, hh:mm A') : '',
    },
    {
      title: 'Generated at',
      dataIndex: 'generatedAt',
      valueType: 'dateRange',
      sorter: true,
      render: (_, { generatedAt }) => generatedAt ? moment(generatedAt).format('MM/DD/YYYY, hh:mm A') : '-',
    },
    {
      title: 'Last modified by',
      dataIndex: 'userId',
      sorter: false,
      renderText: (_, { user }) => user?.login || '',
      renderFormItem: (_, { ...config }) => <SelectUsers {...config} />,
    },
    {
      title: 'Completed by',
      dataIndex: 'completedById',
      sorter: false,
      renderText: (_, { completedBy }) => completedBy?.login || '-',
      renderFormItem: (_, { ...config }) => <SelectUsers {...config} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: getQcReportsBadge,
      valueEnum: ReportStatuses,
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      hideInSearch: true,
      renderText: (attachments, { status, id, record }) => {
        if (status === 'completed') {
          return (
            <>
              <a
                style={{ paddingLeft: 15 }}
                href={`${process.env.REACT_APP_BUCKET_URL}/reports/${id}/show_pdf`}
                target="_blank"
                rel="noreferrer"
              >
                PDF
              </a>
              <Button type="link" onClick={() => setModal(id)}>Send</Button>
            </>
          );
        }

        if (status === 'in progress') {
          return (
            <Button type="link" onClick={() => navigate(`/reports/qc/generate/${id}`, { replace: true })}>
              {record.uid ? 'Send to A-works' : 'Create PDF'}
            </Button>
          );
        }

        return ' ';
      },
    },
  ];

  return (
    <>
      <ModalSendReport category="qc" isOpen={!!modal} close={() => setModal(0)} reportId={modal} />
      <Table<QcTableReportRow>
        formRef={formRef}
        columns={columns}
        request={tableRequest}
        actionRef={actionRef}
        headerTitle="QC Reports list"
        toolBarRender={toolBarRender}
        showSorterTooltip={false}
        columnsState={{ persistenceKey: 'pro-table-qc-reports', persistenceType: 'localStorage' }}
        beforeSearchSubmit={beforeSearchSubmit}
      />
    </>
  );
};

export default TableQcReports;
