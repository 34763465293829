import Search from 'antd/es/input/Search';
import { Key } from 'antd/lib/table/interface';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, Button, FormInstance, message, Radio } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Table from '../../../Common/Table';
import { useAuth } from '../../../../context/auth';
import SelectCustomer from '../../../Common/SelectCustomer';
import SelectCommodity from '../../../Common/SelectCommodity';
import { TemplateStatuses } from '../../../../enums/templates';
import { getMessageInError } from '../../../../hooks/fetch';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { TableTemplateRow, useTableTemplateRow, useTemplateGeneric } from '../../../../hooks/templates';

interface TableTemplates {
  params?: Record<string, string>;
  createButton?: boolean;
  selectedRows?: number[];
  onRowSelection?: (selectedRows: number[]) => void;
}

const TableTemplates: React.FC<TableTemplates> = ({
  params,
  createButton,
  selectedRows,
  onRowSelection,
}): JSX.Element => {
  const { isRoleEnough } = useAuth();
  const formRef = useRef<FormInstance>();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const templatesGet = useTableTemplateRow();
  const templateGeneric = useTemplateGeneric();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [templateGenericId, setTemplateGenericId] = useState<number>(1);

  useEffect(() => {
    templateGeneric.fetch();
  }, []);

  useEffect(() => {
    if (templateGeneric.data && !templateGeneric.error) {
      setTemplateGenericId(templateGeneric.data.id);
    }
  }, [templateGeneric.data]);

  useEffect(() => {
    if (templateGeneric.error) {
      message.error(getMessageInError(templateGeneric.error));
      templateGeneric.clearError();
    }
  }, [templateGeneric.error]);

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      search: value,
      name: searchParams.get('name') || '',
      id: searchParams.get('id') || '',
      commodity: searchParams.get('commodity') || '',
      customers: searchParams.get('customer') || '',
      status: searchParams.get('status') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      orderBy: searchParams.get('orderBy') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if (templatesGet.error) {
      message.error(getMessageInError(templatesGet.error));
      templatesGet.clearError();
    }
  }, [templatesGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    <Button
      key="button"
      type="default"
      loading={templateGeneric.loading}
      onClick={() => onRowSelection ? onRowSelection([templateGenericId]) : (
        navigate(`/templates/generic-template/${templateGenericId}`, { replace: true })
      )}
    >
      {onRowSelection && <Radio checked={selectedRows?.includes(templateGenericId)} />}
      Generic Template
    </Button>,
    createButton ? (
      <>
        {isRoleEnough('manager') && (
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate('/templates/create', { replace: true })}
          >
            Add New
          </Button>
        )}
      </>
    ) : null,
  ], [searchValue, onSearch, templateGenericId]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableTemplateRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return templatesGet.fetch({ ...newParams, ...params }).then((data) => {
      if (data) {
        const { templates, total } = data;

        return ({ data: templates || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  }, [searchParams.get('search')]);

  const columns: ProColumns<TableTemplateRow>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <span key="name">Name</span>,
      dataIndex: 'name',
      sorter: true,
      renderText: (name, { id }) => (
        <>
          {(isRoleEnough('manager') && pathname.includes('templates')) ? (
            <Link to={`/templates/update/${id}`}>{name}</Link>
          ) : name}
        </>
      ),
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity',
      sorter: true,
      renderFormItem: (_, { value }) => <SelectCommodity value={value} />,
    },
    {
      title: 'Customer',
      dataIndex: 'customers',
      sorter: true,
      search: { transform: (() => 'customer') }, // if we using transform - data is losing after page reload
      renderFormItem: (_, { ...config }) => <SelectCustomer {...config} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      valueEnum: TemplateStatuses,
      hideInSearch: !pathname.includes('templates'),
      renderText: (status) => <Badge status={status ? 'success' : 'default'} text={status ? 'Active' : 'Archived'} />,
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      <Table<TableTemplateRow>
        formRef={formRef}
        columns={columns}
        request={tableRequest}
        actionRef={actionRef}
        columnsState={{ persistenceKey: 'pro-table-templates', persistenceType: 'localStorage' }}
        headerTitle="Templates"
        rowSelection={onRowSelection ? { ...rowSelection, type: 'radio' } : false}
        toolBarRender={toolBarRender}
        showSorterTooltip={false}
        beforeSearchSubmit={beforeSearchSubmit}
      />
    </>
  );
};

TableTemplates.defaultProps = {
  params: {},
  createButton: true,
  selectedRows: [],
  onRowSelection: undefined,
};

export default TableTemplates;
