import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import TableLpReportTypes from './Table';
import ModalCreateLpReportType from './Create';
import ModalUpdateLpReportType from './Update';
import { ModalState, ModalTypes } from '../../../types';

const LpReportTypes: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <ModalCreateLpReportType isOpen={modal?.type === ModalTypes.create} close={closeModal} />
      <ModalUpdateLpReportType isOpen={modal?.type === ModalTypes.update} close={closeModal} id={modal?.id || 0} />
      <TableLpReportTypes openModal={openModal} />
    </Content>
  );
};

export default LpReportTypes;
