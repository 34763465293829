import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';

import SelectStatus from '../../../../Common/SelectStatus';
import { email, required } from '../../../../../utils/inputRules';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useContextCustomers } from '../../../../../context/customers';
import { CustomerCreateParams } from '../../../../../hooks/customers';

interface ModalCreateCustomer {
  isOpen: boolean;
  close: () => void;
}

const ModalCreateCustomer: React.FC<ModalCreateCustomer> = ({ isOpen, close }) => {
  const { customerCreate } = useContextCustomers();
  const [form] = Form.useForm();

  const onSubmit = (values: CustomerCreateParams) => {
    customerCreate?.fetch(values);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    if (customerCreate?.data && !customerCreate?.error) {
      message.success('Created!');
      close();
    }
  }, [customerCreate?.data]);

  useEffect(() => {
    if (customerCreate?.error) {
      message.error(getMessageInError(customerCreate?.error));
      customerCreate.clearError();
    }
  }, [customerCreate?.error]);

  return (
    <Modal
      title="Add New Customer"
      visible={isOpen}
      centered
      onCancel={close}
      onOk={handleOk}
      okText="Save"
      confirmLoading={customerCreate?.loading}
      afterClose={form.resetFields}
      destroyOnClose
    >
      <Form
        form={form}
        name="customer_create"
        layout="horizontal"
        labelCol={{ span: 5 }}
        initialValues={{ status: true }}
      >
        <Form.Item name="name" label="Name" rules={required}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={email}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={required}>
          <SelectStatus disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateCustomer;
