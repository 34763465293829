import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, PageHeader, Tag } from 'antd';

import GeneralInfo from './GeneralInfo';
import { useAuth } from '../../../../context/auth';
import TableLoggers from '../../../Common/TableLoggers';
import RecordsStatuses from '../../../../enums/records';
import { useContextRecordUpdate } from '../../../../context/recordUpdate';
import ReportTypesTable from './ReportTypesTable';

const tagStyle = {
  borderRadius: '35px',
  background: 'transparent',
};

const RecordsUpdate: React.FC = () => {
  const navigate = useNavigate();
  const { isRoleEnough, user } = useAuth();
  const { isDisabled, onSave, recordId, recordUpdate } = useContextRecordUpdate();

  const [key, setKey] = useState<string>('');

  useEffect(() => {
    if (recordId?.data && recordUpdate?.data) {
      setKey(JSON.stringify(recordId?.data));
    }
  }, [recordId?.data]);

  const [routes, setRoutes] = useState([
    {
      path: '/records',
      breadcrumbName: 'Records',
    },
    {
      breadcrumbName: recordId?.data?.bolAwb || 'Edit Record',
    },
  ]);

  useEffect(() => {
    if (recordId?.data && !recordId.error) {
      setRoutes([
        {
          path: '/records',
          breadcrumbName: 'Records',
        },
        {
          breadcrumbName: recordId?.data?.bolAwb || 'Edit Record',
        },
      ]);
    }
  }, [recordId?.data]);

  const getRecordTag = useCallback((status: string): JSX.Element => {
    switch (status) {
      case 'in progress':
        return (
          <Tag style={{ ...tagStyle, color: '#FAAD14', borderColor: '#FAAD14' }}>
            {RecordsStatuses.inProgress}
          </Tag>
        );
      case 'completed':
        return (
          <Tag style={{ ...tagStyle, color: '#52c41a', borderColor: '#52c41a' }}>
            {RecordsStatuses.completed}
          </Tag>
        );
      case 'expired':
        return (
          <Tag style={{ ...tagStyle, color: '#d9d9d9', borderColor: '#d9d9d9' }}>
            {RecordsStatuses.expired}
          </Tag>
        );
      case 'not started':
        return (
          <Tag style={{ ...tagStyle, color: '#ff4d4f', borderColor: '#ff4d4f' }}>
            {RecordsStatuses.notStarted}
          </Tag>
        );
      default:
        return <></>;
    }
  }, []);

  return (
    <>
      <PageHeader
        title={recordId?.data?.bolAwb ? (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '16px' }}>
              {recordId?.data?.bolAwb}
            </div>
            {recordId?.data.status ? (
              <div>
                {getRecordTag(recordId.data.status)}
              </div>
            ) : null}
          </div>
        ) : 'Edit Record'}
        extra={(
          <>
            {(isRoleEnough('manager') && !recordId?.data?.uid)
            || (user.role === 'user' as string && !recordId?.data?.uid && recordId?.data?.type === 'misc') ? (
              <Button type="primary" disabled={isDisabled} onClick={onSave} loading={recordUpdate?.loading}>
                Save changes
              </Button>
              ) : null}
          </>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <GeneralInfo />
        <ReportTypesTable />
        <div key={key}>
          <TableLoggers entity="records" />
        </div>
      </Content>
    </>
  );
};

export default RecordsUpdate;
