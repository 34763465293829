import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';

import { required } from '../../../../../utils/inputRules';
import SelectStatus from '../../../../Common/SelectStatus';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useContextCommodities } from '../../../../../context/commodities';
import { CommoditiesCreateParams } from '../../../../../hooks/commodities';

interface ModalCreateCommodity {
  isOpen: boolean;
  close: () => void;
}

const ModalCreateCommodity: React.FC<ModalCreateCommodity> = ({ isOpen, close }) => {
  const { commodityCreate } = useContextCommodities();
  const [form] = Form.useForm();

  const onSubmit = (values: CommoditiesCreateParams) => {
    commodityCreate?.fetch(values);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    if (commodityCreate?.data && !commodityCreate?.error) {
      message.success('Created!');
      close();
    }
  }, [commodityCreate?.data]);

  useEffect(() => {
    if (commodityCreate?.error) {
      message.error(getMessageInError(commodityCreate?.error));
      commodityCreate.clearError();
    }
  }, [commodityCreate?.error]);

  return (
    <Modal
      onOk={handleOk}
      title="Add New Commodity"
      okText="Save"
      visible={isOpen}
      centered
      onCancel={close}
      afterClose={form.resetFields}
      confirmLoading={commodityCreate?.loading}
      destroyOnClose
    >
      <Form
        form={form}
        name="commodity_create"
        layout="horizontal"
        labelCol={{ span: 5 }}
        autoComplete="false"
        initialValues={{ name: '', status: true }}
      >
        <Form.Item name="name" label="Name" rules={required}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={required}>
          <SelectStatus disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateCommodity;
