import { message } from 'antd';
import { useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { FetchSuccess, PagingDataResponse } from '../types';
import { LpReportType, LpReportTypesGetParams, useLpReportTypesGet } from '../hooks/lpReportTypes';
import { RecordId, RecordUpdateParams, useRecordId, useRecordUpdate } from '../hooks/records';
import { DefaultFetchError, FetchGet, FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';

interface RecordsUpdateContext {
  onSave: () => void;
  values: RecordUpdateParams;
  recordId: FetchGetId<RecordId> | null;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  reportTypes: LpReportType[];
  recordUpdate: FetchUpdate<FetchSuccess, DefaultFetchError, RecordUpdateParams> | null;
  reportTypesGet: FetchGet<PagingDataResponse<LpReportType>, LpReportTypesGetParams> | null;
}

export const initialValues: RecordUpdateParams = {
  types: [], // TODO remove with RecordReportType
  warehouse: 0,
  type: '',
  bolAwb: '',
  po: '',
  log_number: '',
  cut_off_date: '',
  customer: 0,
  commodities: [],
  loading_notes: '',
  awb_record_ids: [],
  userGroup: '',
};

const defaultValue: RecordsUpdateContext = {
  onSave: () => undefined,
  values: initialValues,
  recordId: null,
  setValue: () => undefined,
  isDisabled: false,
  reportTypes: [],
  recordUpdate: null,
  reportTypesGet: null,
};

export type ValuesChangeType = { warehouse: number; } | { type: 'awb' | 'bol' | ''; } | { bolAwb: string; }
  | { po: string; } | { log_number: string; } | { cut_off_date: string; } | { customer: number; }
  | { commodities: number[]; } | { loading_notes: string; } | { awb_record_ids: number[]; } | { types: string[]; }
  | { userGroup: string; };

export const RecordsUpdateContext = createContext<RecordsUpdateContext>(defaultValue);

const qcReportType: LpReportType = { // TODO remove
  id: 'qc',
  icon: 'IcSpa',
  name: 'QC Report',
  status: 'active',
};

const RecordsUpdateProvider: React.FC = ({ children }) => {
  const recordId = useRecordId();
  const { id = '' } = useParams<{ id: string; }>();
  const recordUpdate = useRecordUpdate();
  const reportTypesGet = useLpReportTypesGet();
  const [reportTypes, setReportTypes] = useState<LpReportType[]>([]);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<RecordUpdateParams>(initialValues);
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  // TODO report types no more needed on Update page, remove in future with 'types[]'
  useEffect(() => {
    reportTypesGet.fetch({ page: 1, limit: 999, status: 'active' });
  }, []);

  useEffect(() => {
    if (reportTypesGet.data && !reportTypesGet.error && !reportTypes.length) {
      setReportTypes([qcReportType, ...reportTypesGet.data?.items || []]
        .sort((type) => values.types?.find((recordType) => recordType === type.id.toString()) ? -1 : 1));
    }
  }, [reportTypesGet.data, values]);

  useEffect(() => {
    if (reportTypesGet.error) {
      getMessageInError(reportTypesGet.error);
      reportTypesGet.clearError();
    }
  }, [reportTypesGet.error]);

  useEffect(() => {
    const isValid = !!(values.warehouse && values.type && values.bolAwb && (
      values.type === 'awb' ? values.cut_off_date : true)
    );

    setIsDisabled(!isValid);
  }, [values]);

  const onSave = () => {
    recordUpdate.fetch({
      ...values,
      po: values.po || '',
      log_number: values.type === 'awb' ? values.log_number : undefined,
      cut_off_date: values.type === 'awb'
        ? `${moment(values.cut_off_date).format('YYYY-MM-DDTHH:mm:00')}Z`
        : undefined,
      customer: values.customer || 0,
      commodities: values.type === 'bol' ? values.commodities : undefined,
      loading_notes: values.type === 'awb' ? values.loading_notes : undefined,
      awb_record_ids: values.type === 'bol' ? values.awb_record_ids : undefined,
      userGroup: values.type === 'bol' ? values.userGroup : undefined,
      types: undefined, // TODO remove with RecordReportType
    }, id).then(() => {
      message.success('Updated!');
      fetchRecordId();
    });
  };

  const fetchRecordId = () => {
    recordId.fetch(undefined, id);
  };

  useEffect(() => {
    if (recordUpdate.error) {
      message.error(getMessageInError(recordUpdate.error));
      recordUpdate.clearError();
    }
  }, [recordUpdate.error]);

  useEffect(() => {
    if (+id) {
      fetchRecordId();
    }
  }, [id]);

  useEffect(() => {
    if (recordId.data && !recordId.error) {
      setValues({
        warehouse: recordId.data.warehouse?.id || 0,
        type: recordId.data.type || '',
        bolAwb: recordId.data.bolAwb,
        po: recordId.data.po,
        log_number: recordId.data.log_number || '',
        cut_off_date: recordId.data.cut_off_date || '',
        customer: recordId.data.customer?.id || 0,
        commodities: recordId.data.commodities?.map((commodity) => commodity.id),
        loading_notes: recordId.data.loading_notes || '',
        awb_record_ids: recordId.data.get_awb_records.map((record) => record.id),
        userGroup: recordId.data?.userGroup || '',
        types: recordId.data.reports?.map((type) => type.id.toString()), // TODO remove with 'types[]'
      });
    }
  }, [recordId.data]);

  useEffect(() => {
    if (recordId.error) {
      getMessageInError(recordId.error);
      recordId.clearError();
    }
  }, [recordId.error]);

  return (
    <RecordsUpdateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        recordId,
        isDisabled,
        reportTypes,
        recordUpdate,
        reportTypesGet,
      }}
    >
      {children}
    </RecordsUpdateContext.Provider>
  );
};

export default RecordsUpdateProvider;

export const useContextRecordUpdate = (): RecordsUpdateContext => useContext(RecordsUpdateContext);
