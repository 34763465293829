import React from 'react';

/* eslint-disable max-len */

export const IcAcUnit: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 11H17.83L21.07 7.76L19.66 6.34L15 11H13V9L17.66 4.34L16.24 2.93L13 6.17V2H11V6.17L7.76 2.93L6.34 4.34L11 9V11H9L4.34 6.34L2.93 7.76L6.17 11H2V13H6.17L2.93 16.24L4.34 17.66L9 13H11V15L6.34 19.66L7.76 21.07L11 17.83V22H13V17.83L16.24 21.07L17.66 19.66L13 15V13H15L19.66 17.66L21.07 16.24L17.83 13H22V11Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcAccessTime: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="currentColor" fillOpacity="0.85" />
  </svg>
);

export const IcArchive: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM6.24 5H17.76L18.57 5.97H5.44L6.24 5ZM5 19V8H19V19H5ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>

);

export const IcAttachMoney: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.8001 10.9C9.53007 10.31 8.80007 9.7 8.80007 8.75C8.80007 7.66 9.81007 6.9 11.5001 6.9C13.2801 6.9 13.9401 7.75 14.0001 9H16.2101C16.1401 7.28 15.0901 5.7 13.0001 5.19V3H10.0001V5.16C8.06007 5.58 6.50007 6.84 6.50007 8.77C6.50007 11.08 8.41007 12.23 11.2001 12.9C13.7001 13.5 14.2001 14.38 14.2001 15.31C14.2001 16 13.7101 17.1 11.5001 17.1C9.44007 17.1 8.63007 16.18 8.52007 15H6.32007C6.44007 17.19 8.08007 18.42 10.0001 18.83V21H13.0001V18.85C14.9501 18.48 16.5001 17.35 16.5001 15.3C16.5001 12.46 14.0701 11.49 11.8001 10.9Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcAttachment: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 12.5C2 9.46 4.46 7 7.5 7H18C20.21 7 22 8.79 22 11C22 13.21 20.21 15 18 15H9.5C8.12 15 7 13.88 7 12.5C7 11.12 8.12 10 9.5 10H17V12H9.41C8.86 12 8.86 13 9.41 13H18C19.1 13 20 12.1 20 11C20 9.9 19.1 9 18 9H7.5C5.57 9 4 10.57 4 12.5C4 14.43 5.57 16 7.5 16H17V18H7.5C4.46 18 2 15.54 2 12.5Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcBookmark: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 3H7C5.9 3 5.01 3.9 5.01 5L5 21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V5H17V18Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcBuild: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.61 18.99L13.53 9.90997C14.46 7.56997 13.98 4.80997 12.09 2.90997C9.78996 0.609971 6.20996 0.399971 3.65996 2.25997L7.49996 6.10997L6.07996 7.51997L2.24996 3.68997C0.389961 6.22997 0.599962 9.81997 2.89996 12.11C4.75996 13.97 7.46996 14.46 9.78996 13.59L18.9 22.7C19.29 23.09 19.92 23.09 20.31 22.7L22.61 20.4C23.01 20.02 23.01 19.39 22.61 18.99ZM19.61 20.59L10.15 11.13C9.53996 11.58 8.85996 11.85 8.14996 11.95C6.78996 12.15 5.35996 11.74 4.31996 10.7C3.36996 9.75997 2.92996 8.49997 2.99996 7.25997L6.08996 10.35L10.33 6.10997L7.23996 3.01997C8.47996 2.94997 9.72996 3.38997 10.68 4.32997C11.76 5.40997 12.17 6.89997 11.92 8.28997C11.8 8.99997 11.5 9.65997 11.04 10.25L20.49 19.7L19.61 20.59Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcCallMade: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 5V7H15.59L4 18.59L5.41 20L17 8.41V15H19V5H9Z" fill="currentColor" fillOpacity="0.85" />
  </svg>
);

export const IcCallReceived: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20 5.41L18.59 4L7 15.59V9H5V19H15V17H8.41L20 5.41Z" fill="currentColor" fillOpacity="0.85" />
  </svg>

);

export const IcCleaningServices: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 11H15V3C15 1.9 14.1 1 13 1H11C9.9 1 9 1.9 9 3V11H8C5.24 11 3 13.24 3 16V23H21V16C21 13.24 18.76 11 16 11ZM11 3H13V11H11V3ZM19 21H17V18C17 17.45 16.55 17 16 17C15.45 17 15 17.45 15 18V21H13V18C13 17.45 12.55 17 12 17C11.45 17 11 17.45 11 18V21H9V18C9 17.45 8.55 17 8 17C7.45 17 7 17.45 7 18V21H5V16C5 14.35 6.35 13 8 13H16C17.65 13 19 14.35 19 16V21Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcContentPaste: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 11H15V3C15 1.9 14.1 1 13 1H11C9.9 1 9 1.9 9 3V11H8C5.24 11 3 13.24 3 16V23H21V16C21 13.24 18.76 11 16 11ZM11 3H13V11H11V3ZM19 21H17V18C17 17.45 16.55 17 16 17C15.45 17 15 17.45 15 18V21H13V18C13 17.45 12.55 17 12 17C11.45 17 11 17.45 11 18V21H9V18C9 17.45 8.55 17 8 17C7.45 17 7 17.45 7 18V21H5V16C5 14.35 6.35 13 8 13H16C17.65 13 19 14.35 19 16V21Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcDescription: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcDirectionsBoat: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.9999 3V4H10.9999V3H12.9999ZM11.9999 10.11L17.3799 11.88L19.7699 12.66L18.6499 16.63C18.1099 16.33 17.7099 15.92 17.5099 15.69L15.9999 13.96L14.4899 15.68C14.1499 16.08 13.2099 17 11.9999 17C10.7899 17 9.84989 16.08 9.50989 15.68L7.99989 13.96L6.48989 15.68C6.28989 15.91 5.88989 16.31 5.34989 16.61L4.21989 12.65L6.61989 11.86L11.9999 10.11ZM14.9999 1H8.99989V4H5.99989C4.89989 4 3.99989 4.9 3.99989 6V10.62L2.70989 11.04C2.44989 11.12 2.22989 11.3 2.10989 11.54C1.98989 11.78 1.95989 12.06 2.04989 12.32L3.94989 19H3.99989C5.59989 19 7.01989 18.12 7.99989 17C8.97989 18.12 10.3999 19 11.9999 19C13.5999 19 15.0199 18.12 15.9999 17C16.9799 18.12 18.3999 19 19.9999 19H20.0499L21.9399 12.32C22.0199 12.06 21.9999 11.78 21.8799 11.54C21.7599 11.3 21.5399 11.12 21.2799 11.04L19.9999 10.62V6C19.9999 4.9 19.0999 4 17.9999 4H14.9999V1ZM5.99989 9.97V6H17.9999V9.97L11.9999 8L5.99989 9.97ZM15.9999 19.68C14.7799 20.53 13.3899 20.96 11.9999 20.96C10.6099 20.96 9.21989 20.53 7.99989 19.68C6.77989 20.53 5.38989 21 3.99989 21H1.99989V23H3.99989C5.37989 23 6.73989 22.65 7.99989 22.01C9.25989 22.65 10.6299 22.98 11.9999 22.98C13.3699 22.98 14.7399 22.66 15.9999 22.01C17.2599 22.66 18.6199 23 19.9999 23H21.9999V21H19.9999C18.6099 21 17.2199 20.53 15.9999 19.68Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcDoNotDisturb: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM18.31 16.9L7.1 5.69C8.45 4.63 10.15 4 12 4C16.42 4 20 7.58 20 12C20 13.85 19.37 15.55 18.31 16.9Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcEventNote: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 10H7V12H17V10ZM19 3H18V1H16V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V8H19V19ZM14 14H7V16H14V14Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcFlag: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 6L13 4H5V21H7V14H12L13 16H20V6H14ZM18 14H14L13 12H7V6H12L13 8H18V14Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcFlight: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 16V14L13 9V3.5C13 2.67 12.33 2 11.5 2C10.67 2 10 2.67 10 3.5V9L2 14V16L10 13.5V19L8 20.5V22L11.5 21L15 22V20.5L13 19V13.5L21 16Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcFolderOpen: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcGridView: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3V11H11V3H3ZM9 9H5V5H9V9ZM3 13V21H11V13H3ZM9 19H5V15H9V19ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13 13V21H21V13H13ZM19 19H15V15H19V19Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcImage: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM14.14 11.86L11.14 15.73L9 13.14L6 17H18L14.14 11.86Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcImportExport: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 3L5 6.99H8V14H10V6.99H13L9 3ZM16 17.01V10H14V17.01H11L15 21L19 17.01H16Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcInbox: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 3H4.99C3.88 3 3.01 3.89 3.01 5L3 19C3 20.1 3.88 21 4.99 21H19C20.1 21 21 20.1 21 19V5C21 3.89 20.1 3 19 3ZM19 15H15C15 16.66 13.65 18 12 18C10.35 18 9 16.66 9 15H4.99V5H19V15Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcInfo: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcInsertChart: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17ZM19 19H5V5H19V19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcKitchen: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 2.01L6 2C4.9 2 4 2.89 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V4C20 2.89 19.1 2.01 18 2.01ZM18 20H6V10.98H18V20ZM18 9H6V4H18V9ZM8 5H10V8H8V5ZM8 12H10V17H8V12Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcLocalOffer: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcLocalShipping: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcLock: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17ZM18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM8.9 6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H8.9V6ZM18 20H6V10H18V20Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcMail: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 4H4C2.895 4 2.01 4.895 2.01 6L2 18C2 19.105 2.895 20 4 20H20C21.105 20 22 19.105 22 18V6C22 4.895 21.105 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcNearMe: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.27 6.73L13.03 16.86L11.71 13.44L11.39 12.61L10.57 12.29L7.14 10.96L17.27 6.73ZM21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcPlace: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcPortrait: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 12.25C13.24 12.25 14.25 11.24 14.25 10C14.25 8.76 13.24 7.75 12 7.75C10.76 7.75 9.75 8.76 9.75 10C9.75 11.24 10.76 12.25 12 12.25ZM16.5 16.25C16.5 14.75 13.5 14 12 14C10.5 14 7.5 14.75 7.5 16.25V17H16.5V16.25ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcRemoveRedEye: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcSecurity: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcSpa: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.49 9.63C15.31 6.84 14.18 4.12 12.06 2C9.92 4.14 8.74 6.86 8.51 9.63C9.79 10.31 10.97 11.19 12 12.26C13.03 11.2 14.21 10.32 15.49 9.63ZM12.05 5.19C12.68 6.22 13.12 7.37 13.35 8.57C12.88 8.87 12.44 9.2 12.01 9.55C11.59 9.21 11.14 8.88 10.68 8.58C10.93 7.38 11.39 6.23 12.05 5.19ZM12 15.45C11.18 14.2 10.14 13.11 8.94 12.25C8.81 12.16 8.67 12.09 8.54 11.99C8.67 12.08 8.81 12.16 8.93 12.24C6.98 10.83 4.59 10 2 10C2 15.32 5.36 19.82 10.03 21.49C10.66 21.72 11.32 21.89 12 22C12.68 21.88 13.33 21.71 13.97 21.49C18.64 19.82 22 15.32 22 10C17.82 10 14.15 12.17 12 15.45ZM13.32 19.6C12.88 19.75 12.44 19.87 11.99 19.97C11.55 19.88 11.12 19.76 10.71 19.61C7.42 18.43 5.01 15.62 4.26 12.26C5.36 12.52 6.41 12.97 7.38 13.59L7.36 13.6C7.49 13.69 7.62 13.78 7.75 13.85L7.82 13.89C8.81 14.61 9.66 15.5 10.33 16.54L12 19.1L13.67 16.55C14.36 15.5 15.22 14.6 16.2 13.89L16.27 13.84C16.36 13.79 16.45 13.73 16.54 13.67L16.53 13.65C17.51 13 18.6 12.52 19.74 12.25C18.99 15.62 16.59 18.43 13.32 19.6ZM8.99 12.28C8.97 12.27 8.95 12.25 8.94 12.24C8.94 12.24 8.95 12.24 8.95 12.25C8.96 12.26 8.97 12.27 8.99 12.28Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcStarBorder: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcStoreMallDirectory: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.36 9L18.96 12H5.04L5.64 9H18.36ZM20 4H4V6H20V4ZM20 7H4L3 12V14H4V20H14V14H18V20H20V14H21V12L20 7ZM6 18V14H12V18H6Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcStraighten: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.8182 5H2.18182C0.981818 5 0 5.98182 0 7.18182V15.9091C0 17.1091 0.981818 18.0909 2.18182 18.0909H21.8182C23.0182 18.0909 24 17.1091 24 15.9091V7.18182C24 5.98182 23.0182 5 21.8182 5ZM21.8182 15.9091H2.18182V7.18182H4.36364V11.5455H6.54545V7.18182H8.72727V11.5455H10.9091V7.18182H13.0909V11.5455H15.2727V7.18182H17.4545V11.5455H19.6364V7.18182H21.8182V15.9091Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcStyle: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5301 19.65L3.8701 20.21V11.18L1.4401 17.04C1.0301 18.06 1.5201 19.23 2.5301 19.65ZM22.0301 15.95L17.0701 3.98C16.7601 3.23 16.0301 2.77 15.2601 2.75C15.0001 2.75 14.7301 2.79 14.4701 2.9L7.1001 5.95C6.3501 6.26 5.8901 6.98 5.8701 7.75C5.8601 8.02 5.9101 8.29 6.0201 8.55L10.9801 20.52C11.2901 21.28 12.0301 21.74 12.8101 21.75C13.0701 21.75 13.3301 21.7 13.5801 21.6L20.9401 18.55C21.9601 18.13 22.4501 16.96 22.0301 15.95ZM12.8301 19.75L7.8701 7.79L15.2201 4.75H15.2301L20.1801 16.7L12.8301 19.75Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M11 10C11.5523 10 12 9.55228 12 9C12 8.44772 11.5523 8 11 8C10.4477 8 10 8.44772 10 9C10 9.55228 10.4477 10 11 10Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M5.87988 19.75C5.87988 20.85 6.77988 21.75 7.87988 21.75H9.32988L5.87988 13.41V19.75Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcToys: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 23H11V16.43C9.93 17.4 8.52 18 7 18C3.75 18 1 15.25 1 12V11H7.57C6.6 9.93 6 8.52 6 7C6 3.75 8.75 1 12 1H13V7.57C14.07 6.6 15.48 6 17 6C20.25 6 23 8.75 23 12V13H16.43C17.4 14.07 18 15.48 18 17C18 20.25 15.25 23 12 23ZM13 13.13V20.87C14.7 20.41 16 18.83 16 17C16 15.17 14.7 13.59 13 13.13ZM3.13 13C3.59 14.7 5.17 16 7 16C8.83 16 10.41 14.7 10.87 13H3.13ZM13.13 11H20.87C20.41 9.3 18.82 8 17 8C15.18 8 13.59 9.3 13.13 11ZM11 3.13C9.3 3.59 8 5.18 8 7C8 8.82 9.3 10.41 11 10.87V3.13Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcTrain: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.5 16C9.32843 16 10 15.3284 10 14.5C10 13.6716 9.32843 13 8.5 13C7.67157 13 7 13.6716 7 14.5C7 15.3284 7.67157 16 8.5 16Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M15.5 16C16.3284 16 17 15.3284 17 14.5C17 13.6716 16.3284 13 15.5 13C14.6716 13 14 13.6716 14 14.5C14 15.3284 14.6716 16 15.5 16Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M12 2C8 2 4 2.5 4 6V15.5C4 17.43 5.57 19 7.5 19L6 20.5V21H8L10 19H14L16 21H18V20.5L16.5 19C18.43 19 20 17.43 20 15.5V6C20 2.5 16 2 12 2ZM12 4C15.51 4 16.96 4.48 17.57 5H6.43C7.04 4.48 8.49 4 12 4ZM6 7H11V10H6V7ZM18 15.5C18 16.33 17.33 17 16.5 17H7.5C6.67 17 6 16.33 6 15.5V12H18V15.5ZM18 10H13V7H18V10Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcUnarchive: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM6.24 5H17.76L18.59 6H5.42L6.24 5ZM5 19V8H19V19H5ZM8 14H10.55V17H13.45V14H16L12 10L8 14Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcWarningAmberRound: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.0001 5.99005L19.5301 19.0001H4.47012L12.0001 5.99005ZM2.74012 18.0001C1.97012 19.3301 2.93012 21.0001 4.47012 21.0001H19.5301C21.0701 21.0001 22.0301 19.3301 21.2601 18.0001L13.7301 4.99005C12.9601 3.66005 11.0401 3.66005 10.2701 4.99005L2.74012 18.0001ZM11.0001 11.0001V13.0001C11.0001 13.5501 11.4501 14.0001 12.0001 14.0001C12.5501 14.0001 13.0001 13.5501 13.0001 13.0001V11.0001C13.0001 10.4501 12.5501 10.0001 12.0001 10.0001C11.4501 10.0001 11.0001 10.4501 11.0001 11.0001ZM11.0001 16.0001H13.0001V18.0001H11.0001V16.0001Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const IcWeight: React.FC = (props): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C9.79086 2 8 3.79086 8 6V8L6.78078 8C5.86304 8 5.06307 8.62459 4.84049 9.51493L2.34049 19.515C2.02491 20.7773 2.97963 22 4.28077 22H19.7192C21.0204 22 21.9751 20.7773 21.6595 19.515L19.1595 9.51496C18.9369 8.62463 18.137 8.00004 17.2192 8.00003L16 8.00003V6C16 3.79086 14.2091 2 12 2ZM14 8.00002V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V8.00001L14 8.00002ZM6.78077 10L4.28077 20H19.7192L17.2192 10L6.78077 10Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export const icons: Record<string, React.FC> = {
  IcAcUnit,
  IcAccessTime,
  IcArchive,
  IcAttachMoney,
  IcAttachment,
  IcBookmark,
  IcBuild,
  IcCallMade,
  IcCallReceived,
  IcCleaningServices,
  IcContentPaste,
  IcDescription,
  IcDirectionsBoat,
  IcDoNotDisturb,
  IcEventNote,
  IcFlag,
  IcFlight,
  IcFolderOpen,
  IcGridView,
  IcImage,
  IcImportExport,
  IcInbox,
  IcInfo,
  IcInsertChart,
  IcKitchen,
  IcLocalOffer,
  IcLocalShipping,
  IcLock,
  IcMail,
  IcNearMe,
  IcPlace,
  IcPortrait,
  IcRemoveRedEye,
  IcSecurity,
  IcSpa,
  IcStarBorder,
  IcStoreMallDirectory,
  IcStraighten,
  IcStyle,
  IcToys,
  IcTrain,
  IcUnarchive,
  IcWarningAmberRound,
  IcWeight,
};
