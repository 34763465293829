import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import TableCustomers from './Table';
import ModalCreateCustomer from './Modals/Create';
import ModalUpdateCustomer from './Modals/Update';
import { ModalState, ModalTypes } from '../../../types';

const Customers: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <ModalCreateCustomer isOpen={modal?.type === ModalTypes.create} close={closeModal} />
      <ModalUpdateCustomer isOpen={modal?.type === ModalTypes.update} close={closeModal} id={modal?.id || 0} />
      <TableCustomers openModal={openModal} />
    </Content>
  );
};

export default Customers;
