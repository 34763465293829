import ImgCrop from 'antd-img-crop';
import { useParams } from 'react-router-dom';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Modal, Row, Typography, Upload } from 'antd';

import SelectStatus from '../../../Common/SelectStatus';
import { required } from '../../../../utils/inputRules';
import { getBase64 } from '../../../../utils';
import SelectCommodity from '../../../Common/SelectCommodity';
import SelectCustomerMulti from '../../../Common/SelectCustomerMulti';
import { TemplatesUpdateParams, ValuesUpdateChangeType } from '../../../../context/templateUpdate';
import { TemplatesCreateParams, ValuesCreateChangeType } from '../../../../context/templateCreate';

import styles from './index.module.less';

interface State {
  previewImage: string;
  previewTitle: string;
  previewVisible: boolean;
}

interface GeneralInfo {
  values: TemplatesCreateParams | TemplatesUpdateParams;
  onSave: () => void;
  setValue: (value: ValuesCreateChangeType | ValuesUpdateChangeType) => void;
  loading?: boolean;
}

const GeneralInfo: React.FC<GeneralInfo> = ({ values, onSave, setValue, loading }): JSX.Element => {
  const [form] = Form.useForm();
  const { id = '' } = useParams<{ id: string; }>();

  const [state, setState] = useState<State>({ previewVisible: false, previewImage: '', previewTitle: '' });
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        name: values.name,
        status: id ? values.status : true,
        customers: values.customers || null,
        commodity: values.commodity || null,
      });
    }

    if (!uploadFiles.length && values.banner) {
      setUploadFiles([{ ...values.banner, status: 'success' } as UploadFile]);
    }
  }, [form, values]);

  const onChange = ({ file, fileList }: UploadChangeParam) => {
    setValue({ banner: file.originFileObj as File });
    setUploadFiles(fileList);
  };

  const onRemove = () => {
    setValue({ banner: null });
    setUploadFiles([]);
  };

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const onPreview = async (file: UploadFile) => {
    if (file) {
      if (!file.url && !file.preview) {
        // eslint-disable-next-line no-param-reassign
        file.preview = await getBase64(file.originFileObj as Blob) as unknown as string;
      }

      setState({
        ...state,
        previewImage: file.url || file.preview || '',
        previewTitle: file.name || file.url?.substring(file.url.lastIndexOf('/') + 1) || '',
        previewVisible: true,
      });
    }
  };

  const customRequest = ({ onSuccess }: UploadRequestOption): void => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess('ok');
      }
    }, 0);
  };

  return (
    <Card title="General info" style={{ marginBottom: '24px' }} loading={loading}>
      <Form form={form} onFinish={onSave} className="template_create" layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={required}>
              <Input
                type="text"
                onChange={({ target: { value } }) => setValue({ name: value })}
                placeholder="Please enter"
              />
            </Form.Item>
            <Form.Item name="commodity" label="Commodity">
              <SelectCommodity onChange={((value) => setValue({ commodity: value as number }))} activeOnly />
            </Form.Item>
          </Col>
          <Col span={16} style={{ display: 'flex' }}>
            <Form.Item name="banner" label="Banner">
              <ImgCrop rotate>
                <Upload
                  multiple={false}
                  listType="picture-card"
                  fileList={uploadFiles.filter((file) => file.status !== 'removed')}
                  onChange={onChange}
                  onRemove={onRemove}
                  onPreview={onPreview}
                  customRequest={customRequest}
                >
                  {!uploadFiles.filter((file) => file.status !== 'removed').length && '+ Upload'}
                </Upload>
              </ImgCrop>
            </Form.Item>
            <div className={styles.textCenter}>
              <Typography className={styles.text}>
                Recommended image size at least 1024x1024px.
              </Typography>
            </div>
            <Modal title={state.previewTitle} footer={null} visible={state.previewVisible} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
            </Modal>
          </Col>
          <Col span={8}>
            <Form.Item name="customers" label="Customer">
              <SelectCustomerMulti
                onChange={((value) => setValue({ customers: value as number[] }))}
                activeOnly
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'The Status is required' }]}>
              <SelectStatus disabled={!id} onChange={(status) => setValue({ status })} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

GeneralInfo.defaultProps = {
  loading: false,
};

export default GeneralInfo;
