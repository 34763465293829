import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/LpReports/Create';
import { displayName } from '../../../config';
import LpReportsCreateProvider from '../../../context/lpReportCreate';

const LpReportCreate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Report Create`;

  return (
    <Layout>
      <LpReportsCreateProvider>
        <Content />
      </LpReportsCreateProvider>
    </Layout>
  );
};

export default LpReportCreate;
