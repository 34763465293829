import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/es/upload/interface';
import { Checkbox, Modal, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { useEffect, useState } from 'react';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { getBase64 } from '../../../utils';
import styles from './index.module.less';
import { ReportImages } from '../../../context/qcReportUpdateTemplate';

interface State {
  previewImage: string;
  previewTitle: string;
  previewVisible: boolean;
}

interface UploadReportPictures {
  // uploadFiles: UploadFile[];
  oldImages?: ReportImages[];
  galleryImages?: ReportImages[];
  images: UploadFile[];
  onUploadFilesChange: (uploadFiles: UploadFile[], removeImageId?: string) => void;
  onRemoveOldImage?: (id: string) => void;
  selectedImage?: any[];
  setSelectedImages?: any;
  selectToDelete?: boolean;
}

const UploadReportPictures: React.FC<UploadReportPictures> = ({
  oldImages,
  onRemoveOldImage,
  galleryImages,
  onUploadFilesChange,
  images,
  selectedImage,
  setSelectedImages,
  selectToDelete,
}) => {
  const [state, setState] = useState<State>({ previewVisible: false, previewImage: '', previewTitle: '' });

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const handleUploadChange = ({ fileList, file: { status, uid } }: UploadChangeParam) => {
    if (status === 'removed' && oldImages?.map((id) => id.toString()).includes(uid)) {
      onRemoveOldImage?.(uid);
      onUploadFilesChange([], uid);

      return;
    }

    if (status === 'removed') {
      onUploadFilesChange([], uid);

      return;
    }

    onUploadFilesChange(fileList
      .filter((upload) => !oldImages?.map((id) => id.toString())?.includes(upload.uid)));
  };

  const customRequest = ({ onSuccess }: UploadRequestOption): void => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess('ok');
      }
    }, 0);
  };

  const onPreview = async (file: UploadFile & { timeStampString?: string; }) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as Blob) as string;
    }

    setState({
      ...state,
      previewImage: file.url || file.preview || '',
      previewTitle: file.timeStampString || '',
      previewVisible: true,
    });
  };

  useEffect(() => {
    if (!oldImages?.length) {
      return;
    }

    onUploadFilesChange(
      oldImages.map((image): UploadFile => ({
        ...image,
        uid: image.id.toString(),
        name: image.fileName.toString(),
        status: 'done',
        url: `${process.env.REACT_APP_BUCKET_URL}/images/${image.id}`,
        thumbUrl: `${process.env.REACT_APP_BUCKET_URL}/images/${image.id}`,
      })),
    );
  }, [oldImages]);

  useEffect(() => {
    if (!galleryImages) {
      return;
    }

    onUploadFilesChange(
      galleryImages.filter((image) => !images?.some(({ uid }) => +uid === image.id))
        ?.map((image) => ({
          ...image,
          entity: 'gallery',
          uid: image.id.toString(),
          name: image.id.toString(),
          url: `${process.env.REACT_APP_BUCKET_URL}/images/${image.id}`,
          thumbUrl: `${process.env.REACT_APP_BUCKET_URL}/images/${image.id}`,
        })),
    );
  }, [galleryImages]);

  const handleCheckboxChange = (file: any) => {
    const newSelectedFiles = selectedImage?.includes(file)
      ? selectedImage.filter((selectedImages) => selectedImages !== file)
      : [...selectedImage!, file];

    setSelectedImages(newSelectedFiles);
  };

  const itemRender = (originNode: any,
    file: any) => selectToDelete ? (
      <div className="check-box">
        <Checkbox
          style={{ position: 'absolute', padding: '10px', marginLeft: 135 }}
          onChange={() => handleCheckboxChange(file)}
          checked={selectedImage?.includes(file)}
        />
        {file.id
          ? (
            <img
              src={file.url}
              alt={file.name}
              className={styles.image}
            />
          )
          : (
            <img
              src={file.thumbUrl}
              alt={file.name}
              className={styles.image}
            />
          )}
      </div>
  ) : originNode;

  return (
    <div className={images?.length ? 'upload-report-pictures' : ''}>
      <Upload
        multiple
        listType="picture-card"
        onChange={handleUploadChange}
        fileList={images}
        onPreview={onPreview}
        className={images?.length ? '' : styles.hidden}
        customRequest={customRequest}
        defaultFileList={images}
        itemRender={itemRender}
        showUploadList={{ showRemoveIcon: false }}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>

      <div className={images?.length ? styles.hidden : ''}>
        <Dragger
          multiple
          onChange={handleUploadChange}
          fileList={images}
          customRequest={customRequest}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Drag & Drop files here
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload
          </p>
        </Dragger>
      </div>
      <Modal title={state.previewTitle} footer={null} visible={state.previewVisible} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} className={styles.previewModalImage} />
      </Modal>
    </div>
  );
};

UploadReportPictures.defaultProps = {
  oldImages: [],
  onRemoveOldImage: () => undefined,
  galleryImages: [],
  selectedImage: [],
  setSelectedImages: () => undefined,
  selectToDelete: false,
};

export default UploadReportPictures;
