import Search from 'antd/es/input/Search';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons/lib';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, Button, FormInstance, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Table from '../../../Common/Table';
import { useAuth } from '../../../../context/auth';
import { UserStatuses } from '../../../../enums/user';
import { getMessageInError } from '../../../../hooks/fetch';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { TableWarehouseRow, useTableWarehouseRow } from '../../../../hooks/warehouses';

const TableWarehouses: React.FC = (): JSX.Element => {
  const { isRoleEnough } = useAuth();
  const formRef = useRef<FormInstance>();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const warehousesGet = useTableWarehouseRow();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      orderBy: searchParams.get('orderBy') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      name: searchParams.get('name') || '',
      search: value,
      status: searchParams.get('status') || '',
      location: searchParams.get('location') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if (warehousesGet.error) {
      message.error(getMessageInError(warehousesGet.error));
      warehousesGet.clearError();
    }
  }, [warehousesGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    <>
      {isRoleEnough('admin') && (
        <Button
          key="button"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate('/warehouses/create', { replace: true })}
        >
          Add New
        </Button>
      )}
    </>,
  ], [searchValue, onSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableWarehouseRow>>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return warehousesGet.fetch(params).then((data) => {
      if (data) {
        const { warehouses, total } = data;

        return ({ data: warehouses || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  }, [searchParams.get('search')]);

  const columns: ProColumns<TableWarehouseRow>[] = [
    {
      title: 'Name ',
      dataIndex: 'name',
      sorter: true,
      renderText: (login, { id }) => <Link to={`/warehouses/update/${id}`}>{login}</Link>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: true,
    },
    {
      title: 'Qty.users',
      dataIndex: 'users',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status) => <Badge status={status ? 'success' : 'default'} text={status ? 'Active' : 'Archived'} />,
      valueEnum: UserStatuses,
    },
  ];

  return (
    <Table<TableWarehouseRow>
      formRef={formRef}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle="Warehouses list"
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
    />
  );
};

export default TableWarehouses;
