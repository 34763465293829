import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { queryFilterParams } from '../../../utils';
import { useLpReportTypesGet } from '../../../hooks/lpReportTypes';

const SelectLpReportType: React.FC<SelectProps<number | number[], Option>> = ({ ...props }): JSX.Element => {
  const lpReportTypesGet = useLpReportTypesGet();

  const [options, setOptions] = useState<Option[]>([]);
  const [reportTypesName, setReportTypesName] = useState<string>('');

  useEffect(() => {
    if (reportTypesName && reportTypesName.length > 1) {
      const id = setTimeout(() => {
        lpReportTypesGet.fetch(queryFilterParams({
          page: '1',
          limit: '999',
          name: reportTypesName,
          orderBy: 'ASC',
          orderByColumn: 'name',
        }));
      }, 700);

      return () => clearTimeout(id);
    }

    lpReportTypesGet.fetch({ page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name' });

    return undefined;
  }, [reportTypesName]);

  useEffect(() => {
    if (lpReportTypesGet.data?.items.length) {
      setOptions(lpReportTypesGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [lpReportTypesGet.data]);

  useEffect(() => {
    if (lpReportTypesGet.error) {
      message.error(getMessageInError(lpReportTypesGet.error));
      lpReportTypesGet.clearError();
    }
  }, [lpReportTypesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      loading={lpReportTypesGet.loading}
      options={options}
      onSearch={(name) => setReportTypesName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      {...props}
    />
  );
};

export default SelectLpReportType;
