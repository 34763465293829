import { RightOutlined } from '@ant-design/icons/lib';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Collapse, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import RecordsStatuses from '../../../../../enums/records';
import ModalSendReport from '../../../../Common/ModalSendReport';
import ReportCollapseHeader from './ReportCollapseHeader';
import { useReportTemplateGet } from '../../../../../hooks/reportTemplate';
import { TableRecordReportRow } from '../../../../../hooks/records';
import { useContextRecordUpdate } from '../../../../../context/recordUpdate';

const ReportTypesTable: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const reportTemplateGet = useReportTemplateGet();
  const { recordId } = useContextRecordUpdate();

  const [modal, setModal] = useState<number>(0);

  const getRecordBadge = useCallback((status: string): JSX.Element => {
    switch (status) {
      case 'in progress':
        return <Badge status="warning" text={RecordsStatuses.inProgress} />;
      case 'completed':
        return <Badge status="success" text={RecordsStatuses.completed} />;
      case 'expired':
        return <Badge status="default" text={RecordsStatuses.expired} />;
      case 'not started':
        return <Badge status="error" text={RecordsStatuses.notStarted} />;
      default:
        return <></>;
    }
  }, []);

  const getReports = useCallback((reportTypeId: string, name: string): TableRecordReportRow[] => {
    if (recordId?.data?.reports) {
      return recordId?.data?.reports.map((report): TableRecordReportRow => {
        const getItemName = () => {
          if (report.attachments) return report.attachments;
          if (report.type?.name) return `${report.type?.name} ${report.id}`;

          return `${name} ${report.id}`;
        };

        return ({
          id: report.id,
          key: report.id,
          name: getItemName(),
          status: report.status,
          category: report.category,
          attachments: report.attachments,
        });
      });
    }

    return [];
  }, [recordId?.data]);

  useEffect(() => {
    reportTemplateGet.fetch({ page: 1, limit: 999 });
  }, []);

  let reportType = 'Report';

  if (recordId?.data?.type === 'awb') {
    reportType = 'LP reports';
  } else if (recordId?.data?.type === 'bol') {
    reportType = 'QC reports';
  }

  return (
    <>
      <ModalSendReport category="qc" isOpen={!!modal} close={() => setModal(0)} reportId={modal} />
      <Card title="Report types" style={{ marginBottom: '24px' }}>
        <Collapse
          bordered={false}
          accordion
          expandIcon={(props) => (
            <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
              <RightOutlined
                {...props}
                style={{
                  transform: props.isActive ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'all 0.3s linear',
                }}
              />
            </div>
          )}
        >
          {recordId?.data && (
            <>
              {recordId?.data?.type && (
              <Collapse.Panel
                key={recordId?.data?.type === 'awb' ? 'awb' : 'bol'}
                header={(
                  <ReportCollapseHeader
                    icon={recordId?.data?.type === 'awb' ? 'IcFlight' : 'IcSpa'}
                    name={reportType}
                    isOneQcItemId={(recordId.data.type !== 'awb' && recordId?.data?.reports.length === 1
                    && recordId.data.reports[0].status === 'not started')
                      ? recordId.data.reports[0].id : undefined}
                  />
                    )}
                collapsible={recordId.data.reports.length ? undefined : 'disabled'}
              >
                <Table<TableRecordReportRow>
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      render: (recordName, recordType) => (
                        <Link to={`/reports/${recordType.category}/${recordType.id}`}>
                          {recordName}
                        </Link>
                      ),
                    },
                    {
                      title: 'Status',
                      dataIndex: 'status',
                      render: getRecordBadge,
                    },
                    {
                      title: 'Attachments',
                      dataIndex: 'attachments',
                      render: (recordAttachments, recordType) => recordAttachments ? (
                        <>
                          <a
                            style={{ paddingLeft: 15 }}
                            rel="noreferrer"
                            href={`${process.env.REACT_APP_BUCKET_URL}/reports/${recordType.id}/show_pdf`}
                            target="_blank"
                          >
                            PDF
                          </a>
                          <Button type="link" onClick={() => setModal(recordType.id)}>Send</Button>
                        </>
                      ) : recordType.status === 'in progress' && (
                      <Button
                        type="link"
                        onClick={() => navigate(
                          `/reports/${recordType.category}/generate/${recordType.id}`,
                          { replace: true },
                        )}
                      >
                        {recordId?.data?.uid ? 'Send to A-works' : 'Create PDF'}
                      </Button>
                      ),
                    },
                  ]}
                  dataSource={getReports(recordId.data.id.toString(),
                    recordId?.data?.type !== 'awb' ? 'QC report' : 'LP report')}
                  pagination={false}
                />
              </Collapse.Panel>
              )}
            </>
          )}
        </Collapse>
      </Card>
    </>
  );
};

export default ReportTypesTable;
