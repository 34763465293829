import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { CustomersGetParams, useCustomersGet } from '../../../hooks/customers';

const SelectCustomerEmail: React.FC<SelectProps<string | string[], Option>> = ({ ...props }): JSX.Element => {
  const customersGet = useCustomersGet();

  const [options, setOptions] = useState<Option[]>([]);
  const [customersName, setCustomersName] = useState<string>('');

  const fetch = () => {
    const params: CustomersGetParams = {
      page: 1,
      limit: 999,
      orderBy: 'ASC',
      orderByColumn: 'name',
      email: '@',
      status: true,
    };

    if (customersName) {
      params.name = customersName;
    }

    customersGet.fetch(params);
  };

  useEffect(() => {
    if (customersName && customersName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [customersName]);

  useEffect(() => {
    if (customersGet.data?.items.length) {
      setOptions(customersGet.data.items.map(({ email, name }): Option => ({ label: name, value: email })));
    }
  }, [customersGet.data]);

  useEffect(() => {
    if (customersGet.error) {
      message.error(getMessageInError(customersGet.error));
      customersGet.clearError();
    }
  }, [customersGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="tags"
      loading={customersGet.loading}
      options={options}
      onSearch={(name) => setCustomersName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      {...props}
    />
  );
};

export default SelectCustomerEmail;
