import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { CustomersGetParams, useCustomersGet, useCustomersId } from '../../../hooks/customers';
import NotFoundContent from '../NotFoundContent';

interface SelectCustomerMulti extends SelectProps<number[], Option> {
  activeOnly?: boolean;
}

const SelectCustomerMulti: React.FC<SelectCustomerMulti> = ({ activeOnly, ...props }): JSX.Element => {
  const customersGet = useCustomersGet();
  const customersId = useCustomersId();

  const [options, setOptions] = useState<Option[]>([]);
  const [customersName, setCustomersName] = useState<string>('');

  const fetch = () => {
    const params: CustomersGetParams = { page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name' };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (customersName) {
      params.name = customersName;
    }

    customersGet.fetch(params);
  };

  useEffect(() => {
    if (customersName && customersName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [customersName]);

  useEffect(() => {
    if (customersGet.data?.items.length) {
      setOptions(customersGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [customersGet.data]);

  const ref = useRef<boolean>(true);

  useEffect(() => {
    if (options.length && props.value && ref.current) {
      ref.current = false;
      const current = props.value.filter((item) => !options.map(({ value }) => +value).includes(item));

      if (current.length && !customersId.loading) {
        current.forEach((value) => {
          customersId.fetch(undefined, `${value}`)
            .then((res) => setOptions([
              ...options,
              { label: res?.name || '', value },
            ]));
        });
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (customersGet.error) {
      message.error(getMessageInError(customersGet.error));
      customersGet.clearError();
    }
  }, [customersGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="multiple"
      loading={customersGet.loading || customersId.loading}
      options={options}
      onSearch={(name) => setCustomersName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Customer not found." />}
      {...props}
    />
  );
};

SelectCustomerMulti.defaultProps = {
  activeOnly: false,
};

export default SelectCustomerMulti;
