import Search from 'antd/es/input/Search';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons/lib';
import { Key, SortOrder } from 'antd/es/table/interface';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Button, FormInstance, message, Typography } from 'antd';

import Table from '../../../Common/Table';
import { icons } from '../Icons/data';
import LpReportTypeStatuses from '../../../../enums/reports';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState, ModalTypes } from '../../../../types';
import { useContextLpReportTypes } from '../../../../context/lpReportTypes';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { TableLpReportTypeRow, useTableLpReportTypeRowsGet } from '../../../../hooks/lpReportTypes';

interface TableLpReportTypes {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

const TableLpReportTypes: React.FC<TableLpReportTypes> = ({
  params,
  openModal,
  selectedRows,
  onRowSelection,
}): JSX.Element => {
  const formRef = useRef<FormInstance>();
  const tableLpReportTypeRowsGet = useTableLpReportTypeRowsGet();
  const actionRef = useRef<ActionType>();
  const { lpReportTypeCreate, lpReportTypeUpdate } = useContextLpReportTypes();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      search: value,
      name: searchParams.get('name') || '',
      status: searchParams.get('status') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      orderBy: searchParams.get('orderBy') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if ((lpReportTypeCreate?.data && !lpReportTypeCreate?.error)
      || (lpReportTypeUpdate?.data && !lpReportTypeUpdate.error)) {
      actionRef.current?.reload();
    }
  }, [lpReportTypeCreate?.data, lpReportTypeUpdate?.data]);

  useEffect(() => {
    if (tableLpReportTypeRowsGet.error) {
      message.error(getMessageInError(tableLpReportTypeRowsGet.error));
      tableLpReportTypeRowsGet.clearError();
    }
  }, [tableLpReportTypeRowsGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    openModal ? (
      <Button
        key="button"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => openModal({ type: ModalTypes.create })}
      >
        Add New
      </Button>
    ) : null,
  ], [searchValue, onSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLpReportTypeRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return tableLpReportTypeRowsGet.fetch({ ...newParams, ...params }).then((data) => {
      if (data) {
        const { lpReportTypes, total } = data;

        return ({ data: lpReportTypes || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  }, [searchParams.get('search')]);

  const iconsArray = Object.entries(icons);

  const columns: ProColumns<TableLpReportTypeRow>[] = [
    {
      title: 'Name ',
      dataIndex: 'name',
      sorter: true,
      renderText: (name, { id, icon }) => openModal ? (
        <Button type="link" onClick={() => openModal({ type: ModalTypes.update, id: +id })}>
          <Typography.Text className="iconTd colorGreen">
            {iconsArray.filter(([key]) => icon === key).map(([key, Icon]) => (
              <Icon key={key} />
            ))}
          </Typography.Text>
          {name}
        </Button>
      ) : (
        <>
          <Typography.Text className="iconTd">
            {iconsArray.filter(([key]) => icon === key).map(([key, Icon]) => (
              <Icon key={key} />
            ))}
          </Typography.Text>
          {name}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status) => {
        const isActive = status === 'active';

        return <Badge status={isActive ? 'success' : 'default'} text={isActive ? 'Active' : 'Archived'} />;
      },
      hideInSearch: !pathname.includes('lp-report-types'),
      valueEnum: LpReportTypeStatuses,
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableLpReportTypeRow>
      formRef={formRef}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle="LP Report Types"
      rowSelection={onRowSelection ? { ...rowSelection, type: 'radio' } : false}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
    />
  );
};

TableLpReportTypes.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default TableLpReportTypes;
