import React from 'react';
import { Layout, PageHeader } from 'antd';

import Content from '../../components/Pages/LpReportTypes';
import { displayName } from '../../config';
import LpReportTypesProvider from '../../context/lpReportTypes';

const LpReportTypes: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Report Types`;

  return (
    <Layout>
      <PageHeader title="LP Report Types" />
      <LpReportTypesProvider>
        <Content />
      </LpReportTypesProvider>
    </Layout>
  );
};

export default LpReportTypes;
