import React from 'react';
import { Layout, PageHeader } from 'antd';

import Content from '../../components/Pages/Warehouses';
import { displayName } from '../../config';

const Warehouses: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Warehouses`;

  return (
    <Layout>
      <PageHeader title="Warehouses" />
      <Content />
    </Layout>
  );
};

export default Warehouses;
