import React, { createContext, useContext } from 'react';

import { FetchSuccess } from '../types';
import { DefaultFetchError, FetchCreate, FetchUpdate } from '../hooks/fetch';
import { CustomerCreateParams, CustomerUpdateParams, useCustomersCreate, useCustomersUpdate } from '../hooks/customers';

interface CustomersContext {
  customerCreate: FetchCreate<FetchSuccess, DefaultFetchError, CustomerCreateParams> | null;
  customerUpdate: FetchUpdate<FetchSuccess, DefaultFetchError, CustomerUpdateParams> | null;
}

const defaultValue = {
  customerCreate: null,
  customerUpdate: null,
};

export const CustomersContext = createContext<CustomersContext>(defaultValue);

const CustomersProvider: React.FC = ({ children }) => {
  const customerCreate = useCustomersCreate();
  const customerUpdate = useCustomersUpdate();

  return (
    <CustomersContext.Provider value={{ customerCreate, customerUpdate }}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersProvider;

export const useContextCustomers = (): CustomersContext => useContext(CustomersContext);
