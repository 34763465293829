import Card from 'antd/es/card';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, message, PageHeader, Steps } from 'antd';

import { UploadFile } from 'antd/es/upload/interface';
import { Step } from '../../../../types';
import TableRecords from '../../Records/Table';
import TableLpReportTypes from '../../LpReportTypes/Table';
import UploadReportPictures from '../../../Common/UploadReportPictures';
import { useContextLpReportCreate } from '../../../../context/lpReportCreate';
import { useRecordId } from '../../../../hooks/records';
import { getMessageInError } from '../../../../hooks/fetch';
import GalleryModal from '../../QcReports/GalleryModal';

const routes = [
  {
    path: '/reports/lp',
    breadcrumbName: 'LP Reports',
  },
  {
    breadcrumbName: 'Add New LP Report',
  },
];

const LpReportCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id: recordId = '' } = useParams<{ id: string; }>();
  const { isDisabled,
    onSave,
    values,
    setValue,
    lpReportCreate,
    setIsDisabled,
  } = useContextLpReportCreate();
  const recordIdGet = useRecordId();

  const [currentStep, setCurrentStep] = useState(0);
  const [selectToDelete, setSelectToDelete] = useState(false);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (recordId) {
      recordIdGet.fetch(undefined, recordId);
    }
  }, [recordId]);

  useEffect(() => {
    if (recordIdGet.error) {
      message.error(getMessageInError(recordIdGet.error));
      recordIdGet.clearError();
    }
  }, [recordIdGet.error]);

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const handleVisibility = () => {
    setModalVisibility(!modalVisibility);
  };

  const [images, setImages] = useState<any[]>([]);

  const onUploadFilesChange = (uploadFiles: UploadFile[], removeImageId?: string) => {
    if (uploadFiles?.some((el) => el.status === 'uploading') || uploadFiles?.some((el: any) => el?.entity)) {
      setImages((prevState) => ([
        ...prevState,
        ...uploadFiles
          .filter((el) => !prevState.some(({ uid }) => uid.toString() === el.uid.toString())),
      ]));
    }

    if (uploadFiles?.some((el) => el.status === 'done')) {
      if (images.length) {
        setImages((prevState) => ([
          ...prevState.map((obj) => uploadFiles.find((el) => el.uid === obj.uid) || obj),
        ]));
      } else {
        setImages(uploadFiles);
      }
    }

    if (removeImageId?.length) {
      setValue({
        galleryImages: values.galleryImages.filter(({ id }) => id !== +removeImageId),
      });

      setImages(images.filter((el) => el.uid !== removeImageId));
    }
  };

  useEffect(() => {
    setValue({ images });
  }, [images]);

  const SelectToDelete = () => {
    setIsDisabled(false);
    setSelectToDelete(true);
  };

  const CancelDelete = () => {
    setIsDisabled(true);
    setSelectToDelete(false);
  };

  const DeleteSelectedImage = () => {
    setSelectToDelete(false);
    if (selectedImages.length > 0) {
      const newFileList = images.filter((file) => !selectedImages.includes(file));

      setImages(newFileList);
      setSelectedImages([]);
      const newGalleryImages = values.galleryImages.filter(({ id }) => !selectedImages.some((s) => s.id === id));

      setValue({
        galleryImages: newGalleryImages,
        images: newFileList,
      });
    }
  };

  const steps: Step[] = [
    {
      title: 'Record',
      content: (
        <TableRecords
          params={{ type: 'awb' }}
          selectedRows={values.record ? [values.record] : undefined}
          onRowSelection={(records) => setValue({ record: records.length && records[0] })}
        />
      ),
      isValid: !!values.record,
      hidden: !!recordId,
    },
    {
      title: 'Type',
      content: (
        <>
          {recordId && (
            <Card
              headStyle={{ fontWeight: 500 }}
              title="Loading notes"
              style={{ marginBottom: '24px', fontWeight: 600 }}
              loading={recordIdGet.loading}
            >
              {recordIdGet.data?.loading_notes || 'There are no loading notes'}
            </Card>
          )}
          <TableLpReportTypes
            params={{ status: 'active' }}
            selectedRows={values.type ? [values.type] : undefined}
            onRowSelection={(type) => setValue({ type: type.length && type[0] })}
          />
        </>
      ),
      isValid: !!values.type,
    },
    {
      title: 'Photos',
      content: (
        <Card
          title="Add photos"
          extra={(
            <div>
              {selectToDelete === false && (
                <Button type="primary" onClick={SelectToDelete}>
                  Select to Delete
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={CancelDelete}>
                  Cancel
                </Button>
              )}
              {selectToDelete === true && (
                <Button type="primary" onClick={DeleteSelectedImage} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              )}
              <Button type="primary" onClick={handleVisibility} style={{ marginLeft: '10px' }}>
                Upload from Gallery
              </Button>
            </div>
          )}
        >
          <UploadReportPictures
            galleryImages={values.galleryImages}
            images={images}
            onUploadFilesChange={onUploadFilesChange}
            selectedImage={selectedImages}
            setSelectedImages={setSelectedImages}
            selectToDelete={selectToDelete}
          />
        </Card>
      ),
      isValid: !!values.images.length || !!values.galleryImages.length,
    },
  ].filter(({ hidden }) => !hidden);

  return (
    <>
      <PageHeader
        title="Add New LP Report"
        extra={(
          <>
            {currentStep > 0 && (
              <Button type="default" onClick={prev}>
                Back
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={next} disabled={!steps[currentStep].isValid}>
                Next Step
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" disabled={isDisabled} onClick={onSave} loading={lpReportCreate?.loading}>
                Save
              </Button>
            )}
          </>
        )}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        footer={(
          <Steps current={currentStep} style={{ maxWidth: '880px' }}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        )}
      />
      <Content>
        <GalleryModal
          visible={modalVisibility}
          title="QC gallery"
          handleVisibility={handleVisibility}
          setValue={setValue}
          checkedImages={values.galleryImages}
        />
        {steps[currentStep].content}
      </Content>
    </>
  );
};

export default LpReportCreate;
