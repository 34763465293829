import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button } from 'antd';

import { icons } from '../../../../LpReportTypes/Icons/data';
import RecordsStatuses from '../../../../../../enums/records';
import { useContextRecordUpdate } from '../../../../../../context/recordUpdate';

import styles from './index.module.less';

interface ReportCollapseHeader {
  icon: string;
  name: string;
  isOneQcItemId?: number;
}

const ReportCollapseHeader: React.FC<ReportCollapseHeader> = ({ icon, name, isOneQcItemId }) => {
  const navigate = useNavigate();
  const { recordId } = useContextRecordUpdate();
  const Icon = icons[icon];

  const getRecordBadge = (status: string): JSX.Element => {
    switch (status) {
      case 'not started':
        return <Badge status="error" text={RecordsStatuses.notStarted} />;
      case 'completed':
        return <Badge status="success" text={RecordsStatuses.completed} />;
      case 'expired':
        return <Badge status="default" text={RecordsStatuses.expired} />;
      case 'in progress':
        return <Badge status="warning" text={RecordsStatuses.inProgress} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {Icon && (
          <div className={styles.iconWrapper}>
            <Icon />
          </div>
        )}
        {name}
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.statusWrapper}>
          {getRecordBadge(recordId?.data?.status || '')}
        </div>
        {recordId?.data && (
          <Button
            type="ghost"
            onClick={(e) => {
              e.stopPropagation();

              return isOneQcItemId
                ? navigate(
                  `/reports/qc/${isOneQcItemId}`,
                )
                : navigate(
                  `/reports/${recordId?.data?.type !== 'awb' ? 'qc' : 'lp'}/${recordId?.data?.id}/create`,
                  { replace: true },
                );
            }}
            className={styles.btnCreateReport}
          >
            Create report
          </Button>
        )}
      </div>
    </div>
  );
};

ReportCollapseHeader.defaultProps = {
  isOneQcItemId: undefined,
};

export default ReportCollapseHeader;
