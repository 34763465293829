import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { CustomersGetParams, useCustomersGet, useCustomersId } from '../../../hooks/customers';
import NotFoundContent from '../NotFoundContent';

interface SelectCustomer extends SelectProps<number | number[], Option> {
  activeOnly?: boolean;
}

const SelectCustomer: React.FC<SelectCustomer> = ({ activeOnly, ...props }): JSX.Element => {
  const customersGet = useCustomersGet();
  const customersId = useCustomersId();

  const [options, setOptions] = useState<Option[]>([]);
  const [customersName, setCustomersName] = useState<string>('');

  const fetch = () => {
    const params: CustomersGetParams = { page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name' };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (customersName) {
      params.name = customersName;
    }

    customersGet.fetch(params);
  };

  useEffect(() => {
    if (customersName && customersName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [customersName]);

  useEffect(() => {
    if (customersGet.data?.items.length) {
      setOptions(customersGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [customersGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        customersId.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: props.value as number },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (customersGet.error) {
      message.error(getMessageInError(customersGet.error));
      customersGet.clearError();
    }
  }, [customersGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      loading={customersGet.loading || customersId.loading}
      options={options}
      onSearch={(name) => setCustomersName(name)}
      allowClear
      showSearch
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Customer not found." />}
      {...props}
    />
  );
};

SelectCustomer.defaultProps = {
  activeOnly: false,
};

export default SelectCustomer;
