import React, { useEffect, useState } from 'react';
import { Badge, Card, Form, Image, Input, List, message, Radio, Select } from 'antd';

import { useSearchParams } from 'react-router-dom';
import { Option } from '../../../types';
import { required } from '../../../utils/inputRules';
import { getMessageInError } from '../../../hooks/fetch';
import { TemplateFieldValue } from '../../../hooks/qcReports';
import { TemplateField, useTemplatesId } from '../../../hooks/templates';
import { queryFilterParams } from '../../../utils';

export const gradingColors: string[] = ['#FF4D4F', '#FA8214', '#FAAD14', '#52C41A'];

interface TemplateForm {
  id: number;
  title?: string;
  onSave: () => void;
  setIsDisabled: (isValid: boolean) => void;
  setTemplateFields: (templateFields: TemplateFieldValue[]) => void;
  templateFieldsValues: TemplateFieldValue[];
}

const TemplateForm: React.FC<TemplateForm> = ({
  id,
  title,
  onSave,
  setIsDisabled,
  setTemplateFields,
  templateFieldsValues,
}) => {
  const templatesId = useTemplatesId();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const [templateIdFields, setTemplateIdFields] = useState<TemplateField[]>([]);

  const handleValueChange = (key: number, value: string) => {
    const updatedArray = templateFieldsValues.map((obj) => {
      if (obj.id === key) {
        return { ...obj, value }; // Update existing object
      }

      return obj; // Keep the object as is
    });

    // If the object doesn't exist, add it to the array
    if (!updatedArray.some((obj) => obj.id === key)) {
      updatedArray.push({ id: key, value });
    }

    setTemplateFields(updatedArray);
  };

  const getField = (templateField: TemplateField) => {
    if (templateField.required
      && !templateFieldsValues?.find((x) => x?.id === templateField?.id)?.value) setIsDisabled(true);
    switch (templateField.type) {
      case 'grading':
        return (
          <Radio.Group>
            {templateField.value.map((field, index) => (
              <Radio.Button
                className={field.id.toString() === templateFieldsValues.find((templateFieldsValue) => (
                  templateFieldsValue.id === templateField.id
                ))?.value ? `radio-button-${index}` : undefined}
                key={field.id}
                value={index.toString()}
                onClick={() => handleValueChange(templateField.id, index.toString())}
              >
                <Badge color={gradingColors[index]} text={field.value} />
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      case 'text':
        return (
          <Input
            type="text"
            onChange={({ target: { value } }) => handleValueChange(templateField.id, value)}
            placeholder="Please enter"
          />
        );
      case 'select':
        return (
          <Select
            options={templateField.value.map((value): Option => ({ label: value.value, value: value.value }))}
            onChange={(value) => handleValueChange(templateField.id, value)}
            placeholder="Please select"
          />
        );
      case 'yes':
        return (
          <Radio.Group>
            <Radio.Button value="1" onClick={() => handleValueChange(templateField.id, '1')}>Yes</Radio.Button>
            <Radio.Button value="0" onClick={() => handleValueChange(templateField.id, '0')}>No</Radio.Button>
          </Radio.Group>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (id) {
      templatesId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (templatesId.data && !templatesId.error) {
      setTemplateIdFields(templatesId.data.templateFields
        .sort((prev, next) => prev.position - next.position ? -1 : 1)
        .map((field): TemplateField => ({
          ...field,
          value: field.value?.split(',').map((value, i) => ({ id: i, value })),
        })));
    }
  }, [templatesId.data]);

  useEffect(() => {
    if (templatesId.error) {
      message.error(getMessageInError(templatesId.error));
      templatesId.clearError();
    }
  }, [templatesId.error]);

  useEffect(() => {
    if (form && templateFieldsValues.length) {
      const values: Record<string, string | number | string[] | number[]> = {};

      templateFieldsValues.forEach((templateField) => {
        values[templateField.id] = templateField.value;
      });

      form.setFieldsValue(values);

      const requiredFields: TemplateField[] = templateIdFields.filter((field) => field.required) || [];

      setIsDisabled(!requiredFields.every((requiredField) => (
        !!templateFieldsValues.find((field) => field.id === requiredField.id)?.value
      )));
    }
  }, [form, templateFieldsValues, templateIdFields]);

  useEffect(() => {
    setSearchParams(queryFilterParams({
      valid: '',
      step: searchParams.get('step') || '',
    }));
  }, []);

  return (
    <Card title={title} loading={templatesId.loading}>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onSave}
        className="template_form"
        onFieldsChange={() => {
          const requiredFieldsTwo: TemplateField[] = templateIdFields.filter((field) => field.required) || [];

          const isEveryFilled = requiredFieldsTwo.every((requiredField) => (
            !!form.getFieldsValue()[requiredField?.id]
          ));

          if (isEveryFilled) {
            setSearchParams(queryFilterParams({
              valid: 'true',
              step: searchParams.get('step') || '',
            }));
          } else {
            setSearchParams(queryFilterParams({
              valid: '',
              step: searchParams.get('step') || '',
            }));
          }
        }}
      >
        <List itemLayout="horizontal">
          {templatesId.data?.banner && (
            <List.Item>
              <Image
                src={`${process.env.REACT_APP_BUCKET_URL}/images/${templatesId.data?.banner?.id || 0}`}
                width={362}
              />
            </List.Item>
          )}
          {templateIdFields.map((templateField) => (
            <List.Item key={templateField.id}>
              <Form.Item
                name={templateField.id}
                label={templateField.name}
                rules={templateField.required ? required : undefined}
              >
                {getField(templateField)}
              </Form.Item>
            </List.Item>
          ))}
        </List>
      </Form>
    </Card>
  );
};

TemplateForm.defaultProps = {
  title: 'Fill the form',
};

export default TemplateForm;
