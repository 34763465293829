import React from 'react';
import { Content } from 'antd/es/layout/layout';

import TableLpReports from './Table';

const LpReports: React.FC = (): JSX.Element => (
  <Content>
    <TableLpReports />
  </Content>
);

export default LpReports;
