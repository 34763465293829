import Search from 'antd/es/input/Search';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, Button, FormInstance, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Table from '../../../Common/Table';
import { useAuth } from '../../../../context/auth';
import CommodityStatuses from '../../../../enums/commodities';
import { getMessageInError } from '../../../../hooks/fetch';
import { useContextCommodities } from '../../../../context/commodities';
import { ModalState, ModalTypes } from '../../../../types';
import { Commodity, useCommoditiesGet } from '../../../../hooks/commodities';
import { getSorterParams, queryFilterParams } from '../../../../utils';

interface TableCommodities {
  openModal: (modal: ModalState) => void;
}

const TableCommodities: React.FC<TableCommodities> = ({ openModal }): JSX.Element => {
  const { isRoleEnough } = useAuth();
  const formRef = useRef<FormInstance>();
  const navigate = useNavigate();
  const commoditiesGet = useCommoditiesGet();
  const actionRef = useRef<ActionType>();
  const { commodityCreate, commodityUpdate } = useContextCommodities();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      search: value,
      name: searchParams.get('name') || '',
      id: searchParams.get('id') || '',
      status: searchParams.get('status') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      orderBy: searchParams.get('orderBy') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if ((commodityCreate?.data && !commodityCreate?.error) || (commodityUpdate?.data && !commodityUpdate.error)) {
      actionRef.current?.reload();
    }
  }, [commodityCreate?.data, commodityUpdate?.data]);

  useEffect(() => {
    if (commoditiesGet.error) {
      message.error(getMessageInError(commoditiesGet.error));
      commoditiesGet.clearError();
    }
  }, [commoditiesGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    <>
      {isRoleEnough('manager') && (
        <Button
          key="button"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => openModal({ type: ModalTypes.create })}
        >
          Add New
        </Button>
      )}
    </>,
  ], [searchValue, onSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<Commodity>>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return commoditiesGet.fetch(params).then((data) => {
      if (data) {
        return ({ data: data.items || [], success: true, total: data.meta.totalItems });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  }, [searchParams.get('search')]);

  const columns: ProColumns<Commodity>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <span key="name">Name</span>,
      dataIndex: 'name',
      sorter: true,
      renderText: (name, { id }) => (
        <>
          {isRoleEnough('manager') ? (
            <Button type="link" onClick={() => openModal({ type: ModalTypes.update, id })}>
              {name}
            </Button>
          ) : name}
        </>
      ),
    },
    {
      title: 'Qty. templates',
      dataIndex: 'templateCount',
      hideInSearch: true,
      sorter: true,
      renderText: (templateCount, { id }) => (
        <Button
          key="button"
          type="link"
          onClick={() => navigate(`/templates?commodity=${id}`, { replace: true })}
        >
          {templateCount}
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      valueEnum: CommodityStatuses,
      renderText: (status) => <Badge status={status ? 'success' : 'default'} text={status ? 'Active' : 'Archived'} />,
    },
  ];

  return (
    <>
      <Table<Commodity>
        formRef={formRef}
        columns={columns}
        request={tableRequest}
        actionRef={actionRef}
        headerTitle="Commodities"
        columnsState={{ persistenceKey: 'pro-table-commodities', persistenceType: 'localStorage' }}
        toolBarRender={toolBarRender}
        showSorterTooltip={false}
        beforeSearchSubmit={beforeSearchSubmit}
      />
    </>
  );
};

export default TableCommodities;
