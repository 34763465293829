import React, { useEffect } from 'react';
import { Form, message, Modal } from 'antd';

import { required } from '../../../utils/inputRules';
import { useReportSend } from '../../../hooks/qcReports';
import { ReportCategory } from '../../../hooks/lpReports';
import SelectCustomerEmail from '../SelectCustomerEmail';
import { getMessageInError } from '../../../hooks/fetch';
import { useAuth } from '../../../context/auth';

interface ModalSendReport {
  close: () => void;
  isOpen: boolean;
  category: ReportCategory;
  reportId: number;
}

const ModalSendReport: React.FC<ModalSendReport> = ({ isOpen, close, reportId }) => {
  const reportSend = useReportSend();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const onSubmit = (value: { emails: string[]; }) => {
    reportSend.fetch({ id: reportId, emails: value.emails });
  };

  useEffect(() => {
    if (reportSend.data && !reportSend.error) {
      message.success('PDF successfully sent.');
      close();
    }
  }, [reportSend.data]);

  useEffect(() => {
    if (reportSend.error) {
      message.error(getMessageInError(reportSend.error));
      reportSend.clearError();
    }
  }, [reportSend.error]);

  const handleOk = () => form.validateFields().then(onSubmit);

  return (
    <Modal
      onOk={handleOk}
      title="Send Report to Email"
      okText="Send"
      visible={isOpen}
      centered
      onCancel={close}
      afterClose={form.resetFields}
      okButtonProps={{ loading: reportSend.loading }}
      destroyOnClose
      confirmLoading={false}
    >
      <Form
        form={form}
        name="send_report_to_email"
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={handleOk}
        autoComplete="false"
        initialValues={{
          emails: user.email?.includes('@') ? [user.email] : undefined,
        }}
      >
        <Form.Item name="emails" label="Email" rules={required} style={{ marginBottom: 0 }}>
          <SelectCustomerEmail />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalSendReport;
