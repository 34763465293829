import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';

import SelectIcon from '../SelectIcon';
import { required } from '../../../../utils/inputRules';
import SelectLpReportStatus from '../../../Common/SelectLpReportStatus';
import { getMessageInError } from '../../../../hooks/fetch';
import { useContextLpReportTypes } from '../../../../context/lpReportTypes';

export const objOfAwTypes = {
  'Loading Pictures': 'Loading Pictures',
  Temperature: 'Temp Recorders',
  'Doc/Pouch': 'Loading Documents',
  'Documents/Pouch': 'Loading Documents',
  'Receiving Pictures': 'Receiving Pictures',
};

export const objOfAwDocTypes = {
  'Loading Pictures': 'LP',
  Temperature: 'TEMP',
  'Doc/Pouch': 'DOCS',
  'Documents/Pouch': 'DOCS',
  'Receiving Pictures': 'RP',
};

interface ModalCreateLpReportType {
  isOpen: boolean;
  close: () => void;
}

const ModalCreateLpReportType: React.FC<ModalCreateLpReportType> = ({ isOpen, close }): JSX.Element => {
  const { lpReportTypeCreate } = useContextLpReportTypes();
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then(({ name, icon }) => {
      const foundAwTypesValue = Object.entries(objOfAwTypes).find((item) => (
        name.toLowerCase() === item[0].toLocaleLowerCase()));
      const foundAwDocTypesValue = Object.entries(objOfAwDocTypes).find((item) => (
        name.toLowerCase() === item[0].toLocaleLowerCase()));

      lpReportTypeCreate?.fetch({
        name,
        icon,
        awType: foundAwTypesValue ? foundAwTypesValue[1] : '',
        awDocType: foundAwDocTypesValue ? foundAwDocTypesValue[1] : '',
      });
    });
  };

  useEffect(() => {
    if (lpReportTypeCreate?.data && !lpReportTypeCreate.error) {
      message.success('Created!');
      close();
    }
  }, [lpReportTypeCreate?.data]);

  useEffect(() => {
    if (lpReportTypeCreate?.error) {
      message.error(getMessageInError(lpReportTypeCreate.error));
      lpReportTypeCreate.clearError();
    }
  }, [lpReportTypeCreate?.error]);

  return (
    <>
      <Modal
        onOk={handleOk}
        title="Add New LP Report Type"
        okText="Save"
        visible={isOpen}
        onCancel={close}
        afterClose={form.resetFields}
        confirmLoading={lpReportTypeCreate?.loading}
        destroyOnClose
      >
        <Form
          form={form}
          name="lp_report_create_create"
          layout="horizontal"
          labelCol={{ span: 5 }}
          autoComplete="false"
          initialValues={{ status: 'active' }}
        >
          <Form.Item name="name" label="Name" rules={required}>
            <Input type="text" placeholder="Please enter" />
          </Form.Item>
          <Form.Item name="icon" label="Image" rules={required}>
            <SelectIcon value="icon" />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={required}>
            <SelectLpReportStatus disabled />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateLpReportType;
