import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, PageHeader } from 'antd';

import GeneralInfo from '../GeneralInfo';
import TableLoggers from '../../../Common/TableLoggers';
import QualityReportFields from '../QualityReportFields';
import { useContextTemplateUpdate } from '../../../../context/templateUpdate';

const routes = [
  {
    path: '/templates',
    breadcrumbName: 'Templates',
  },
  {
    breadcrumbName: 'Edit Template',
  },
];

const TemplateUpdate: React.FC = () => {
  const navigate = useNavigate();
  const { values, onSave, setValue, isDisabled, templateId } = useContextTemplateUpdate();

  return (
    <>
      <PageHeader
        title="Edit Template"
        extra={(
          <Button type="primary" disabled={isDisabled} onClick={onSave}>
            Save changes
          </Button>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <GeneralInfo setValue={setValue} onSave={onSave} values={values} loading={templateId?.loading} />
        <div style={{ marginBottom: '24px' }}>
          <QualityReportFields
            onSave={onSave}
            loading={templateId?.loading}
            templateFields={values.templateFields}
            setTemplateFields={(templateFields) => setValue({ templateFields })}
          />
        </div>
        <TableLoggers entity="templates" />
      </Content>
    </>
  );
};

export default TemplateUpdate;
