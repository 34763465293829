import React from 'react';
import { Layout, PageHeader } from 'antd';

import Content from '../../components/Pages/LpReports';
import { displayName } from '../../config';

const LpReports: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Reports`;

  return (
    <Layout>
      <PageHeader title="LP Reports" />
      <Content />
    </Layout>
  );
};

export default LpReports;
