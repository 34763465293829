import { FetchSuccess } from '../types';
import { QcReportTemplate } from './qcReports';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export const useReportTemplateCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchCreate('reports/templates')
);

export interface ReportTemplate {
  id: number;
  images: { id: number; }[];
  inWork: null;
  updated: string;
  created: string;
  template: {
    id: number;
    name: string;
    banner: { id: number; };
    templateFields: { id: number; }[];
  };
  reportTemplateFields: {
    id: number;
    value: string;
    templateField: { id: number; };
    reportTemplate: { id: number; };
  }[];
}

export interface ReportTemplateGetParams {
  page: number;
  limit: number;
}

export const useReportTemplateGet = (): FetchGet<ReportTemplate, ReportTemplateGetParams> => (
  useFetchGet('reports/templates')
);

export const useReportTemplateGetId = (): FetchGetId<QcReportTemplate> => (
  useFetchGetId('reports/templates', '', { autoStart: false })
);

export const useReportTemplateUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchUpdate('reports/templates')
);

export const useReportTemplateUpdateMobile = (): FetchUpdate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchUpdate('reports/templates/mobile')
);
