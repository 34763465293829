import React, { createContext, useContext } from 'react';

import { FetchSuccess } from '../types';
import { DefaultFetchError, FetchCreate, FetchUpdate } from '../hooks/fetch';
import {
  LpReportTypeCreateParams,
  LpReportTypeUpdateParams,
  useLpReportTypeCreate,
  useLpReportTypeUpdate,
} from '../hooks/lpReportTypes';

interface LpReportTypesContext {
  lpReportTypeCreate: FetchCreate<FetchSuccess, DefaultFetchError, LpReportTypeCreateParams> | null;
  lpReportTypeUpdate: FetchUpdate<FetchSuccess, DefaultFetchError, LpReportTypeUpdateParams> | null;
}

const defaultValue = {
  lpReportTypeCreate: null,
  lpReportTypeUpdate: null,
};

export const LpReportTypesContext = createContext<LpReportTypesContext>(defaultValue);

const LpReportTypesProvider: React.FC = ({ children }) => {
  const lpReportTypeCreate = useLpReportTypeCreate();
  const lpReportTypeUpdate = useLpReportTypeUpdate();

  return (
    <LpReportTypesContext.Provider value={{ lpReportTypeCreate, lpReportTypeUpdate }}>
      {children}
    </LpReportTypesContext.Provider>
  );
};

export default LpReportTypesProvider;

export const useContextLpReportTypes = (): LpReportTypesContext => useContext(LpReportTypesContext);
