import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { TemplatesGetParams, useTemplateGeneric, useTemplatesGet } from '../../../hooks/templates';
import NotFoundContent from '../NotFoundContent';

interface SelectTemplate extends SelectProps<number | number[], Option> {
  activeOnly?: boolean;
}

const SelectTemplate: React.FC<SelectTemplate> = ({ activeOnly, ...props }): JSX.Element => {
  const templatesGet = useTemplatesGet();
  const templateGeneric = useTemplateGeneric();

  const [options, setOptions] = useState<Option[]>([]);
  const [reportTypesName, setReportTypesName] = useState<string>('');

  const fetch = () => {
    const params: TemplatesGetParams = { page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name', status: true };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (reportTypesName) {
      params.name = reportTypesName;
    }

    templatesGet.fetch(params);
  };

  useEffect(() => {
    templateGeneric.fetch();
  }, []);

  useEffect(() => {
    if (templateGeneric.data && !templateGeneric.error) {
      setOptions([
        { label: templateGeneric.data.name, value: templateGeneric.data.id },
        ...(templatesGet.data?.items.map(({ name, id }): Option => ({ label: name, value: id })) || []),
      ]);
    }
  }, [templateGeneric.data]);

  useEffect(() => {
    if (templateGeneric.error) {
      getMessageInError(templateGeneric.error);
      templateGeneric.clearError();
    }
  }, [templateGeneric.error]);

  useEffect(() => {
    if (reportTypesName && reportTypesName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [reportTypesName]);

  useEffect(() => {
    if (templatesGet.data?.items.length) {
      setOptions(templateGeneric.data ? [
        { label: templateGeneric.data.name, value: templateGeneric.data.id },
        ...templatesGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })),
      ] : templatesGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [templatesGet.data]);

  useEffect(() => {
    if (templatesGet.error) {
      message.error(getMessageInError(templatesGet.error));
      templatesGet.clearError();
    }
  }, [templatesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      loading={templatesGet.loading}
      options={options}
      onSearch={(name) => setReportTypesName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Template not found." />}
      {...props}
    />
  );
};

SelectTemplate.defaultProps = {
  activeOnly: false,
};

export default SelectTemplate;
