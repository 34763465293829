import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface CustomerSimple {
  id: number;
  name: string;
  status: boolean;
}

export interface Customer extends CustomerSimple {
  email: string;
  created: string;
  updated: string;
  templateCount: string;
}

export interface CustomersGetParams {
  page?: number;
  name?: string;
  limit?: number;
  email?: string;
  search?: string;
  status?: boolean;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'email' | 'name' | 'templateCount' | 'status' | 'id';
}

export interface CustomerCreateParams {
  name: string;
  email: string;
}

export interface CustomerUpdateParams {
  name: string;
  email: string;
  status: boolean;
}

export const useCustomersGet = (): FetchGet<PagingDataResponse<Customer>, CustomersGetParams> => useFetchGet(
  'customers',
  { autoStart: false },
);

export const useCustomersCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, CustomerCreateParams> => (
  useFetchCreate('customers')
);

export const useCustomersUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, CustomerUpdateParams> => (
  useFetchUpdate('customers')
);

export const useCustomersId = (): FetchGetId<Customer> => useFetchGetId('customers', '', { autoStart: false });
