import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';

interface Confirm {
  onOk: () => void;
  title: string;
  content: string | JSX.Element;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  disabled?: boolean;
  icon?: JSX.Element;
}

const confirm = ({
  onOk,
  onCancel,
  title,
  content,
  okText = 'Confirm',
  cancelText = 'Cancel',
  disabled,
  icon,
}: Confirm)
  : void => {
  Modal.confirm({
    title,
    icon: icon || <ExclamationCircleOutlined />,
    content,
    cancelText,
    okText,
    onCancel,
    onOk,
    okButtonProps: {
      disabled,
    },
  });
};

export default confirm;
