import React, { createContext, useContext } from 'react';

import { FetchSuccess } from '../types';
import { DefaultFetchError, FetchCreate, FetchUpdate } from '../hooks/fetch';
import {
  CommoditiesCreateParams,
  CommoditiesUpdateParams,
  useCommoditiesCreate,
  useCommoditiesUpdate,
} from '../hooks/commodities';

interface CommoditiesContext {
  commodityCreate: FetchCreate<FetchSuccess, DefaultFetchError, CommoditiesCreateParams> | null;
  commodityUpdate: FetchUpdate<FetchSuccess, DefaultFetchError, CommoditiesUpdateParams> | null;
}

const defaultValue = {
  commodityCreate: null,
  commodityUpdate: null,
};

export const CommoditiesContext = createContext<CommoditiesContext>(defaultValue);

const CommoditiesProvider: React.FC = ({ children }) => {
  const commodityCreate = useCommoditiesCreate();
  const commodityUpdate = useCommoditiesUpdate();

  return (
    <CommoditiesContext.Provider value={{ commodityCreate, commodityUpdate }}>
      {children}
    </CommoditiesContext.Provider>
  );
};

export default CommoditiesProvider;

export const useContextCommodities = (): CommoditiesContext => useContext(CommoditiesContext);
