import { Form, Input, message, Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';

import Loading from '../../../../Common/Loading';
import SelectStatus from '../../../../Common/SelectStatus';
import { email, required } from '../../../../../utils/inputRules';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useContextCustomers } from '../../../../../context/customers';
import { CustomerUpdateParams, useCustomersId } from '../../../../../hooks/customers';

interface ModalUpdateCustomer {
  id: number;
  isOpen: boolean;
  close: () => void;
}

const ModalUpdateCustomer: React.FC<ModalUpdateCustomer> = ({ id, isOpen, close }) => {
  const { customerUpdate } = useContextCustomers();
  const customerId = useCustomersId();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      customerId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (form && customerId.data && !customerId.error && form) {
      form.setFieldsValue({
        name: customerId.data.name,
        email: customerId.data.email,
        status: customerId.data.status,
      });
    }
  }, [form, customerId.data]);

  useEffect(() => {
    if (customerId.error) {
      getMessageInError(customerId.error);
      customerId.clearError();
    }
  }, [customerId.error]);

  const onSubmit = useCallback((values: CustomerUpdateParams) => {
    customerUpdate?.fetch(values, id);
  }, [customerUpdate, id]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    if (customerUpdate?.data && !customerUpdate?.error) {
      message.success('Updated!');
      close();
    }
  }, [customerUpdate?.data]);

  useEffect(() => {
    if (customerUpdate?.error) {
      message.error(getMessageInError(customerUpdate?.error));
      customerUpdate.clearError();
    }
  }, [customerUpdate?.error]);

  return (
    <Modal
      onOk={handleOk}
      title="Edit Customer"
      okText="Save changes"
      visible={isOpen}
      centered
      onCancel={close}
      afterClose={form.resetFields}
      confirmLoading={customerUpdate?.loading}
      destroyOnClose
    >
      <Loading absolute visible={customerId.loading} />
      <Form
        form={form}
        name="customer_create"
        layout="horizontal"
        labelCol={{ span: 5 }}
        initialValues={{ status: true }}
      >
        <Form.Item name="name" label="Name" rules={required}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={email}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={required}>
          <SelectStatus />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUpdateCustomer;
